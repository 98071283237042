import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from './hooks/useScrollTop';
import 'nprogress/nprogress.css';
import App from './App';
import { SidebarProvider } from './contexts/SidebarContext.js';
import reportWebVitals from './reportWebVitals';
import { ChatbotProvider } from './contexts/ChatbotContext';
// import { Provider } from 'react-redux';
// import store from './store';

ReactDOM.render(
  <HelmetProvider>
    {/* <Provider store={store}> */}
    <ChatbotProvider>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <App />
        </BrowserRouter>
      </SidebarProvider>
    </ChatbotProvider>
    {/* </Provider> */}
  </HelmetProvider>,
  document.getElementById('root')
);

reportWebVitals();
