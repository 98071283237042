import { useState, forwardRef, useEffect } from 'react';
import { formatDate } from 'src/utils/helper';
import {
  Avatar,
  Box,
  Checkbox,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Button,
  Typography,
  Dialog,
  styled,
  CircularProgress
} from '@mui/material';
import { getTextTypeLabel, constructSearchURL } from 'src/utils/helper';
import CloseIcon from '@mui/icons-material/Close';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useTranslation } from 'react-i18next';
import BulkActions from 'src/components/Files/BulkActions';
import TextDetailsModal from './TextDetailsModal';
import { withRetryOnAuthFailure } from 'src/utils/axios';
import { deleteText, searchAllTexts } from 'src/utils/text-service';
import SearchSection from '../Generic/SearchSection';
import FilterSection from '../Generic/FilterSection';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ArticleTable = ({
  status = 'COMPLETED',
  page,
  limit,
  setPage,
  setLimit
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [paginatedItems, setpaginatedItems] = useState([]);
  const [paginationMetadata, setPagiantionMetadata] = useState({});
  const [searchMetadta, setSearchMetadta] = useState({});
  const [searchData, setSearchDaata] = useState({
    value: null,
    search_field: null,
    search_type: null,
    from: null,
    to: null
  });
  const [orderByData, setOrderByData] = useState({
    order_by_field: null,
    order_by: null
  });

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [edited, setEdited] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(0);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeItems =
    selectedItems.length > 0 && selectedItems.length < paginatedItems.length;
  const selectedAllItems = selectedItems.length === paginatedItems.length;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(0);
  let { botId } = useParams();


  function searchArticles() {
    const query = constructSearchURL({
      from: searchData.from,
      orderBy: orderByData.order_by,
      orderByField: orderByData.order_by_field,
      page: page,
      pageSize: limit,
      searchField: searchData.search_field,
      searchType: searchData.search_type,
      status: status,
      to: searchData.to,
      value: searchData.value
    });

    withRetryOnAuthFailure(searchAllTexts, query, botId)
      .then((res) => {
        setpaginatedItems(res.results);
        setPagiantionMetadata(res.metadata);
        setSearchMetadta(res.metadata.search_metadata);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    searchArticles(
      searchData,
      orderByData,
      page,
      limit,
      status,
      setpaginatedItems,
      setPagiantionMetadata,
      setSearchMetadta,
      setLoading
    );
  }, [searchData, page, limit, edited, status, orderByData]);

  const handleSelectAllItems = (event) => {
    setSelectedItems(
      event.target.checked ? paginatedItems.map((item) => item.id) : []
    );
  };

  const handleSelectOneItem = (event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [...prevSelected, itemId]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== itemId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const handleConfirmDelete = (id) => {
    setOpenConfirmDelete(id);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(0);
  };

  const handleDeleteCompleted = () => {
    setLoading(true);
    withRetryOnAuthFailure(deleteText, openConfirmDelete, botId)
      .then(() => {
        searchArticles();
        toast.success(t('Article deleted successfully!'));
        setOpenConfirmDelete(0);
      })
      .catch((err) => {
        console.log(err);
        searchArticles();
        toast.error(
          t('There has been problem with deleting article. Please try again.')
        );
        setOpenConfirmDelete(0);
      });
  };

  const bulkDelete = () => {
    setLoading(true);
    for (let i = 0; i < selectedItems.length; i++) {
      withRetryOnAuthFailure(deleteText, selectedItems[i], botId)
        .then(() => {
          searchArticles();
          setSelectedItems([]);
        })
        .catch((err) => {
          console.log(err);
          searchArticles();
          toast.error(
            t('There has been problem with deleting articles. Please try again.')
          );
          setSelectedItems([]);
        });
    }
    toast.success(t('Articles deleted sucessfully!'));
  };

  const handleDetailsModal = (id) => {
    setOpenDetailsModal(id);
  };

  const closeDetailsModal = () => {
    setOpenDetailsModal(null);
  };

  const changedDetailsModal = () => {
    setOpenDetailsModal(null);
    setEdited(!edited);
  };

  const handleSearchCange = (data) => {
    if (!(data.search_type === null && searchData.search_type === null)) {
      setSearchDaata(data);
      setOrderByData({
        order_by: null,
        order_by_field: null
      });
    }
  };

  const handleFilterCange = (data) => {
    setOrderByData(data);
    setPage(0);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        {selectedBulkActions && (
          <Box flex={1} p={2}>
            <BulkActions onDelete={bulkDelete} />
          </Box>
        )}
        {!selectedBulkActions && (
          <Box flex={1} py={2}>
            <Box
              sx={{
                mb: { xs: 2, md: 0 }
              }}
            >
              <SearchSection
                metadata={searchMetadta}
                onSubmited={handleSearchCange}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        p={1}
      >
        <FilterSection
          metadata={searchMetadta}
          onSubmited={handleFilterCange}
        />
      </Box>
      <Divider />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress size={64} disableShrink thickness={3} />
        </Box>
      ) : paginatedItems.length === 0 ? (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any text content matching your search criteria")}
        </Typography>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllItems}
                      indeterminate={selectedSomeItems}
                      onChange={handleSelectAllItems}
                    />
                  </TableCell>
                  <TableCell align="center">{t('Title')}</TableCell>
                  <TableCell align="center">{t('Upload date')}</TableCell>
                  <TableCell align="center">{t('Article type')}</TableCell>
                  <TableCell align="left">{t('Content length')}</TableCell>
                  <TableCell align="center">{t('Actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedItems.map((item) => {
                  const isItemSelected = selectedItems.includes(item.id);
                  return (
                    <TableRow hover key={item.id} selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) =>
                            handleSelectOneItem(event, item.id)
                          }
                          value={isItemSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          component="div"
                          maxWidth={300}
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {item.title}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {item.start_at ? formatDate(item.start_at) : t('Unknown')}
                      </TableCell>
                      <TableCell align="center">
                        {t(getTextTypeLabel(item.type))}
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" noWrap>
                          {item.content_length} {t("characters")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          <Tooltip title={t('View')} arrow>
                            <IconButton
                              onClick={() => handleDetailsModal(item.id)}
                              color="primary"
                            >
                              <LaunchTwoToneIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('Delete')} arrow>
                            <IconButton
                              onClick={() => handleConfirmDelete(item.id)}
                              color="primary"
                            >
                              <DeleteTwoToneIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <TextDetailsModal
                        open={openDetailsModal === item.id}
                        onClose={() => closeDetailsModal(item.id)}
                        Transition={Transition}
                        id={item.id}
                        onChange={changedDetailsModal}
                      />
                      <DialogWrapper
                        open={openConfirmDelete === item.id}
                        maxWidth="sm"
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => closeConfirmDelete(item.id)}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                          p={5}
                        >
                          <AvatarError>
                            <CloseIcon />
                          </AvatarError>

                          <Typography
                            align="center"
                            sx={{
                              pt: 4,
                              px: 6
                            }}
                            variant="h3"
                          >
                            {t('Do you really want to delete this items')}?
                          </Typography>

                          <Typography
                            align="center"
                            sx={{
                              pt: 2,
                              pb: 4,
                              px: 6
                            }}
                            fontWeight="normal"
                            color="text.secondary"
                            variant="h4"
                          >
                            {t("You won't be able to revert after deletion")}
                          </Typography>

                          <Box>
                            <Button
                              variant="text"
                              size="large"
                              sx={{
                                mx: 1
                              }}
                              onClick={closeConfirmDelete}
                            >
                              {t('Cancel')}
                            </Button>
                            <ButtonError
                              onClick={handleDeleteCompleted}
                              size="large"
                              sx={{
                                mx: 1,
                                px: 3
                              }}
                              variant="contained"
                            >
                              {t('Delete')}
                            </ButtonError>
                          </Box>
                        </Box>
                      </DialogWrapper>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={paginationMetadata.total_items || 0}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
    </>
  );
};

ArticleTable.defaultProps = {
  items: []
};

export default ArticleTable;
