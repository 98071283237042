import { Suspense, lazy } from 'react';
import SuspenseLoader from '../components/SuspenseLoader';
import { Navigate } from 'react-router';
import PrivacyPolicyPage from 'src/pages/PrivacyPolicyPage';
import TermsOfServicePage from 'src/pages/TermsOfServicePage';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Status

const Status404 = Loader(
  lazy(() => import('../content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('../content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('../content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('../content/pages/Status/Maintenance'))
);

const NotActivated = Loader(
  lazy(() => import('../content/pages/Status/NotActivated'))
);

const baseRoutes = [
  {
    path: 'status',
    children: [
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      },
      {
        path: 'not-activated',
        element: <NotActivated />
      }
    ]
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicyPage />
  },
  {
    path: 'terms-of-service',
    element: <TermsOfServicePage />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: '',
    element: (
      <Navigate to={`/${localStorage.getItem('REACT_APP_CHATBOT_ID')}/Jobs`} />
    )
  }
];

export default baseRoutes;
