import React from 'react';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';

const CardInformations = ({
  header,
  subheader,
  actionButton = null,
  children,
  style = {} // Allow custom styles to be passed
}) => {
  return (
    <Card
      style={{
        height: 'auto', // Adjust this to set the desired fixed height
        display: 'flex',
        flexDirection: 'column',
        margin: 0, // Remove any margin
        padding: 0, // Remove any padding
        ...style // Allow additional custom styles to be applied
      }}
    >
      <CardHeader title={header} subheader={subheader} action={actionButton} />
      <Divider />
      <CardContent style={{ overflow: 'auto' }}>{children}</CardContent>
    </Card>
  );
};

export default CardInformations;
