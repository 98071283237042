// import React from 'react';
// import {
//   Dialog,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Button,
//   Link,
//   Typography,
//   Divider
// } from '@mui/material';

// const CookiesPrivacyPopup = ({ open, onClose }) => {
//   return (
//     <Dialog
//       open={open}
//       onClose={(event, reason) => {
//         if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
//           onClose();
//         }
//       }}
//       aria-labelledby="privacy-policy-title"
//       aria-describedby="privacy-policy-description"
//     >
//       <DialogContent
//         sx={{
//           padding: '24px',
//           paddingTop: '24px',
//           justifyContent: 'center',
//           textAlign: 'center'
//         }}
//       >
//         <Typography variant="h4" color="primary" gutterBottom mb={1.5}>
//           Cookie Consent
//         </Typography>
//         <Divider
//           style={{
//             marginBottom: '16px'
//           }}
//         />
//         <DialogContentText
//           id="privacy-policy-description"
//           sx={{ color: 'text.secondary' }}
//         >
//           We use cookies to improve your experience on our site. By continuing
//           to use this website, you consent to the use of cookies in accordance
//           with our{' '}
//           <Link href="/privacy-policy" color="secondary">
//             Privacy Policy
//           </Link>
//           .
//         </DialogContentText>
//         <Divider
//           style={{
//             marginTop: '16px'
//           }}
//         />
//       </DialogContent>
//       <DialogActions sx={{ paddingBottom: '16px', justifyContent: 'center' }}>
//         <Button
//           onClick={onClose}
//           variant="contained"
//           color="primary"
//           sx={{ textTransform: 'none', fontSize: '1rem' }}
//         >
//           Accept
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default CookiesPrivacyPopup;

// import React from 'react';
// import {
//   Dialog,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Button,
//   Link,
//   Typography,
//   Divider,
//   Grid
// } from '@mui/material';

// const CookiesPrivacyPopup = ({ open, onClose }) => {
//   return (
//     <Dialog
//       open={open}
//       onClose={(event, reason) => {
//         if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
//           onClose();
//         }
//       }}
//       aria-labelledby="privacy-policy-title"
//       aria-describedby="privacy-policy-description"
//     >
//       <DialogContent
//         sx={{
//           paddingY: '12px',
//           justifyContent: 'center',
//           textAlign: 'center'
//         }}
//       >
//         <Grid container spacing={2} alignItems="center">
//           <Grid item xs={12} sm={6}>
//             <img
//               src="/static/images/privacy_bot.png"
//               alt="Welcome"
//               style={{ width: '80%', height: 'auto' }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
//             <Typography variant="h4" color="Highlight" gutterBottom mb={1}>
//               Cookie Consent
//             </Typography>
//             <Divider
//               style={{
//                 marginBottom: '12px',
//                 marginTop: '12px'
//               }}
//             />
//             <DialogContentText
//               id="privacy-policy-description"
//               sx={{ color: 'text.secondary' }}
//             >
//               We use cookies to improve your experience on our site. By
//               continuing to use this website, you consent to the use of cookies
//               in accordance with our{' '}
//               <Link href="/privacy-policy" color="Highlight">
//                 Privacy Policy
//               </Link>
//               .
//             </DialogContentText>
//           </Grid>
//         </Grid>
//       </DialogContent>
//       <DialogActions sx={{ paddingBottom: '16px', justifyContent: 'center' }}>
//         <Button
//           onClick={onClose}
//           variant="contained"
//           color="primary"
//           sx={{ textTransform: 'none', fontSize: '1rem' }}
//         >
//           Accept
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default CookiesPrivacyPopup;

import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Link,
  Typography,
  Divider,
  Grid,
  useTheme
} from '@mui/material';
import logo from 'src/images/LogoBot.svg';

const CookiesPrivacyPopup = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      aria-labelledby="privacy-policy-title"
      aria-describedby="privacy-policy-description"
      PaperProps={{
        style: {
          border: '1px solid #d3d4d5',
          borderRadius: theme.shape.borderRadius // Use the theme's border radius
        }
      }}
    >
      <DialogContent
        sx={{
          justifyContent: 'center',
          textAlign: 'center',
          padding: 0
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <img
              src={logo}
              alt="Welcome"
              style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                paddingLeft: '16px',
                paddingTop: '32px'
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              padding: theme.spacing(3) // Improved padding all around
            }}
          >
            <Typography variant="h4" color="primary" gutterBottom>
              Cookie Consent
            </Typography>
            <Divider sx={{ marginY: 2 }} />
            <DialogContentText
              id="privacy-policy-description"
              sx={{ color: 'text.secondary', textAlign: 'left' }}
            >
              We use cookies to improve your experience on our site. By
              continuing to use this website, you consent to the use of cookies
              in accordance with our{' '}
              <Link href="/privacy-policy" color="primary">
                Privacy Policy
              </Link>
              .
            </DialogContentText>
            <DialogActions sx={{ justifyContent: 'center', marginTop: '24px' }}>
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: 'none',
                  fontSize: '1rem',
                  width: '100%',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    borderColor: theme.palette.primary.main
                  }
                }}
              >
                Accept
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CookiesPrivacyPopup;
