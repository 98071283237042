import React, { useEffect, useState, useRef } from 'react';
import { Grid, Button, Tooltip, Typography } from '@mui/material';
import InstagramPostCard from './InstagramPostCard';
import CardInformations from '../Generic/CardInformations';
import { withRetryOnAuthFailure } from 'src/utils/axios';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { updatePostMetadata } from 'src/utils/instagram-service';
import CardAttributes from '../Generic/CardAttributes';
import CardEditableAttributes from '../Generic/CardEditableAttributes';
import InstagramProductList from './InstagramProductList';
import { useTranslation } from 'react-i18next';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useParams } from 'react-router';

const InstagramPost = ({ post }) => {
  const [currentPost, setCurrentPost] = useState(post);
  const { t } = useTranslation();
  const { botId } = useParams();
  const [showProductForm, setShowProductForm] = React.useState(false);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const [attributes, setAttributes] = useState([
    { name: 'Media type', value: currentPost._media_type },
    { name: 'Media link', value: currentPost._permalink },
    { name: 'Created at', value: formatDate(currentPost.start_at) },
    { name: 'Updated at', value: formatDate(currentPost._updated_at) }
  ]);
  const [editableAttributes, setEditableAttributes] = useState([
    {
      name: 'Custom metadata',
      value: currentPost.metadata,
      attribute: 'metadata'
    }
  ]);

  const [InstagramPostCardHeight, setInstagramPostCardHeight] = useState(0);
  const InstagramPostCardRef = useRef(null);
  useEffect(() => {
    if (InstagramPostCardRef.current) {
      setInstagramPostCardHeight(InstagramPostCardRef.current.clientHeight);
    }
  }, [currentPost]);

  const formatDistanceToNow = (date) => {
    return new Date(date).toLocaleDateString();
  };

  useEffect(() => {
    setAttributes([
      { name: 'Media type', value: currentPost._media_type },
      { name: 'Media link', value: currentPost._permalink },
      { name: 'Created at', value: formatDate(currentPost.start_at) },
      { name: 'Updated at', value: formatDate(currentPost._updated_at) }
    ]);
    setEditableAttributes([
      {
        name: 'Custom metadata',
        value: currentPost.metadata,
        attribute: 'metadata'
      }
    ]);
  }, [currentPost]);

  const handleSave = (name, value) => {
    withRetryOnAuthFailure(
      updatePostMetadata,
      currentPost.id,
      {
        [name]: value
      },
      botId
    )
      .then((res) => {
        setCurrentPost(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [rotationAngle, setRotationAngle] = React.useState(0);

  const handleToggleForm = () => {
    const newRotationAngle = rotationAngle === 0 ? 90 : 0;
    setRotationAngle(newRotationAngle);
    setShowProductForm(!showProductForm);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      style={{ maxWidth: 1400, margin: 'auto', minHeight: '80vh' }}
    >
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          height: 'auto'
        }}
        ref={InstagramPostCardRef}
      >
        <InstagramPostCard
          post={currentPost}
          style={{ width: '100%', height: '100%' }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        lg={5}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          maxHeight: InstagramPostCardHeight,
          marginBottom: '0px'
        }}
      >
        <CardInformations
          header={
            currentPost.post_type === 'STORY'
              ? 'Story ' + t('metadata')
              : 'Post ' + t('metadata')
          }
          subheader={'ID: ' + currentPost.id}
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <CardAttributes attributes={attributes} />
          <CardEditableAttributes
            editableAttributes={editableAttributes}
            onSave={handleSave}
          />
        </CardInformations>
        <CardInformations
          header={t('Image Summary')}
          subheader={
            t('Summary created') +
            ': ' +
            formatDistanceToNow(currentPost._updated_at)
          }
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <CardAttributes
            attributes={[{ name: 'Summary', value: currentPost.media_summary }]}
          />
        </CardInformations>
        <CardInformations
          header="Instagram Products"
          subheader="Products tagged in the post"
          style={{
            height: '100%',
            width: '100%'
          }}
          actionButton={
            <Button
              onClick={handleToggleForm}
              sx={{
                borderRadius: '50%',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50px',
                transform: `rotate(${rotationAngle}deg)`,
                transition: 'transform 0.5s ease',
                '&:hover': {
                  backgroundColor: 'transparent',
                  outline: 'none'
                },
                '&:focus': {
                  backgroundColor: 'transparent',
                  outline: 'none'
                }
              }}
            >
              <Tooltip
                placement="top"
                arrow
                title={showProductForm ? t('Close') : t('Add new product')}
              >
                {showProductForm ? (
                  <CloseTwoToneIcon fontSize="small" />
                ) : (
                  <Typography
                    variant="button"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                  >
                    Add new
                    <AddTwoToneIcon fontSize="small" />
                  </Typography>
                )}
              </Tooltip>
            </Button>
          }
        >
          <InstagramProductList
            postId={currentPost.id}
            products={currentPost.products}
            showForm={showProductForm}
            editTrigered={() => handleToggleForm()}
          />
        </CardInformations>
      </Grid>
    </Grid>
  );
};

export default InstagramPost;
