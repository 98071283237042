import { useEffect, useState } from 'react';
import { config } from 'src/config';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useChatbots } from 'src/contexts/ChatbotContext';

export function useAuthToken() {
  const [token, setToken] = useState(localStorage.getItem(config.token_key));
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem(config.refresh_token_key)
  );
  const [LoggedIn, setLoggedIn] = useState({ username: '' });
  const navigate = useNavigate();

  const { flushChatbots } = useChatbots();

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === config.token_key) {
        setToken(e.newValue);
      }
      if (e.key === config.refresh_token_key) {
        setRefreshToken(e.newValue);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (token) {
      fetchLoggedInData(token)
        .then((res) => {
          if (!isMounted) return;
          setLoggedIn(res);
        })
        .catch((err) => {
          console.error(err);
          if (!isMounted) return;
          logOut();
          if (err.response?.status === 401) {
            navigate('/status/not-activated');
          } else {
            navigate('/Login');
          }
        });
    } else {
      setLoggedIn(null);
    }

    return () => {
      isMounted = false;
    };
  }, [token]);


  const fetchLoggedInData = async (token) => {
    const customConfig = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const res = await axios.get(`${config.api_url}/whoami/`, customConfig);
    return res.data;
  };

  // const saveTokens = (newToken, refreshToken) => {
  //   localStorage.setItem(config.token_key, newToken);
  //   localStorage.setItem(config.refresh_token_key, refreshToken);
  //   setToken(newToken);
  //   setRefreshToken(refreshToken);
  //   fetchLoggedInData(newToken)
  //     .then((res) => {
  //       setLoggedIn(res);
  //     })
  //     .catch((err) => {
  //       if (err.response?.status === 401) {
  //         logOut();
  //       }
  //       throw err;
  //     });
  // };

  const saveTokens = async (newToken, refreshToken) => {
    localStorage.setItem(config.token_key, newToken);
    localStorage.setItem(config.refresh_token_key, refreshToken);
    setToken(newToken);
    setRefreshToken(refreshToken);
    try {
      const res = await fetchLoggedInData(newToken)
      setLoggedIn(res);
    }
    catch (err) {
      if (err.response?.status === 401) {
        logOut();
      }
      throw err;
    }
  };

  const getToken = () => {
    return token;
  };

  const getRefreshToken = () => {
    return refreshToken;
  };

  const logOut = () => {
    localStorage.removeItem(config.token_key);
    localStorage.removeItem(config.refresh_token_key);
    localStorage.removeItem('REACT_APP_CHATBOT_ID');
    setToken(null);
    setRefreshToken(null);
    setLoggedIn(null);
    flushChatbots();
  };

  const getDecodedToken = () => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Error decoding token:', error);
    }
    return null;
  };

  const isAuthenticated = () => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return (
      token !== null &&
      getDecodedToken().exp &&
      getDecodedToken().exp > currentTimestamp
    );
  };

  return {
    LoggedIn,
    saveTokens,
    getToken,
    logOut,
    isAuthenticated,
    getRefreshToken
  };
}
