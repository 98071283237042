import axios from 'axios';
import { config } from 'src/config';

export const addText = async (dto, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.post(`${config.api_url}/text/${botId}`, dto);
  return res.data;
};

export const updateText = async (dto, id, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.put(`${config.api_url}/text/${botId}/${id}/`, dto);
  return res.data;
};

export const getAllTexts = async (page, pageSize, status, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(
    `${config.api_url}/text/${botId}/?page=${page}&page_size=${pageSize}&status=${status}`
  );
  return res.data;
};

export const searchAllTexts = async (queries, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/text/${botId}/search${queries}`);
  return res.data;
};

export const getTextById = async (id, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/text/${botId}/${id}/`);
  return res.data;
};

export const deleteText = async (id, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.delete(`${config.api_url}/text/${botId}/${id}/`);
  return res.data;
};
