import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { withRetryOnAuthFailure } from 'src/utils/axios';
import { getAllBots } from 'src/utils/chatbot_service';

const ChatbotContext = createContext();

export const useChatbots = () => useContext(ChatbotContext);

export const ChatbotProvider = ({ children }) => {
  const [chatbots, setChatbots] = useState(null);
  const [activeChatbot, setActiveChatbot] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const addChatbot = (chatbot) => {
    setChatbots((prevChatbots) => [...prevChatbots, chatbot]);
    setActiveChatbot(chatbot);
  };

  const flushChatbots = () => {
    setChatbots([]);
    setActiveChatbot(null);
  };

  const removeChatbot = (chatbotId) => {
    const newChatbots = chatbots.filter((bot) => bot.id !== chatbotId);
    setChatbots(newChatbots);

    if (activeChatbot && activeChatbot.id === chatbotId) {
      const newActiveChatbot = newChatbots.length > 0 ? newChatbots[0] : null;
      // console.log('newActiveChatbot', newActiveChatbot);
      setActiveChatbot(newActiveChatbot);

      if (newActiveChatbot) {
        localStorage.setItem('REACT_APP_CHATBOT_ID', newActiveChatbot.id);
      } else {
        localStorage.removeItem('REACT_APP_CHATBOT_ID');
      }
    }
  };

  const fetchChatbots = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await withRetryOnAuthFailure(getAllBots);
      setChatbots(response);
      if (
        !localStorage.getItem('REACT_APP_CHATBOT_ID') &&
        response.length > 0
      ) {
        localStorage.setItem('REACT_APP_CHATBOT_ID', response[0].id);
        setActiveChatbot(response[0]);
      } else if (localStorage.getItem('REACT_APP_CHATBOT_ID')) {
        const chatbot = response.find(
          (bot) => bot.id === localStorage.getItem('REACT_APP_CHATBOT_ID')
        );
        setActiveChatbot(chatbot);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchChatbots();
  }, [fetchChatbots]);

  return (
    <ChatbotContext.Provider
      value={{
        chatbots,
        activeChatbot,
        setActiveChatbot,
        fetchChatbots,
        addChatbot,
        flushChatbots,
        isLoading,
        removeChatbot
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};
