import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Grid, Typography, Button, Tooltip, Divider } from '@mui/material';
import InstagramPostCard from './InstagramPostCard';
import { useTranslation } from 'react-i18next';
import { updatePostMetadata } from 'src/utils/instagram-service';
import { withRetryOnAuthFailure } from 'src/utils/axios';
import { useParams } from 'react-router';
import CardInformations from '../Generic/CardInformations';
import CardAttributes from '../Generic/CardAttributes';
import CardEditableAttributes from '../Generic/CardEditableAttributes';
import InstagramProductList from './InstagramProductList';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CardEditableAttribute from '../Generic/CardEditableAttribute';

const InstagramPost2 = ({ post }) => {
  const [currentPost, setCurrentPost] = useState(post);
  const { t } = useTranslation();
  const { botId } = useParams();
  const [showProductForm, setShowProductForm] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [leftHeight, setLeftHeight] = useState(0);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const [attributes, setAttributes] = useState([
    { name: 'Media type', value: currentPost._media_type },
    { name: 'Media link', value: currentPost._permalink },
    { name: 'Created at', value: formatDate(currentPost.start_at) },
    { name: 'Updated at', value: formatDate(currentPost._updated_at) }
  ]);

  const [editableAttributes, setEditableAttributes] = useState([
    {
      name: 'Custom metadata',
      value: currentPost.metadata,
      attribute: 'metadata'
    }
  ]);

  const handleToggleForm = () => {
    const newRotationAngle = rotationAngle === 0 ? 90 : 0;
    setRotationAngle(newRotationAngle);
    setShowProductForm(!showProductForm);
  };

  const handleSave = (name, value) => {
    withRetryOnAuthFailure(
      updatePostMetadata,
      currentPost.id,
      {
        [name]: value
      },
      botId
    )
      .then((res) => {
        setCurrentPost(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const leftRef = useCallback((node) => {
    if (node !== null) {
      setLeftHeight(node.clientHeight);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (leftRef.current) {
        setLeftHeight(leftRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [leftRef]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      style={{
        maxWidth: 1400,
        margin: 'auto',
        // add border to the container
        // border: '1px solid #000',
        marginBottom: 30
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        lg={5}
        ref={leftRef}
        style={{
          //   border: '1px solid #000',
          padding: 0,
          backgroundColor: '#f0f0f0'
        }}
      >
        {/* test */}
        <InstagramPostCard
          post={currentPost}
          style={{ width: '100%', height: '100%' }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Grid container direction="column" spacing={3}>
          <Grid
            item
            style={{
              height: `${leftHeight / 2}px`,
              //   border: '1px solid #000',
              padding: 2,
              marginLeft: 15,
              backgroundColor: '#f0f0f0'
            }}
          >
            <CardInformations
              header={
                currentPost.post_type === 'STORY'
                  ? 'Story ' + t('metadata')
                  : 'Post ' + t('metadata')
              }
              subheader={'ID: ' + currentPost.id}
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <CardAttributes attributes={attributes} />
              <Divider />
              <CardEditableAttribute
                editableAttributes={editableAttributes}
                onSave={handleSave}
              />
            </CardInformations>
          </Grid>
          <Grid
            item
            style={{
              height: `${leftHeight / 2}px`,
              //   border: '1px solid #000',
              padding: 2,
              marginLeft: 15,
              backgroundColor: '#f0f0f0'
            }}
          >
            {/* test3 */}
            <CardInformations
              header="Instagram Products"
              subheader="Products tagged in the post"
              style={{
                height: '100%',
                width: '100%'
              }}
              actionButton={
                <Button
                  onClick={handleToggleForm}
                  sx={{
                    borderRadius: '50%',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50px',
                    transform: `rotate(${rotationAngle}deg)`,
                    transition: 'transform 0.5s ease',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      outline: 'none'
                    },
                    '&:focus': {
                      backgroundColor: 'transparent',
                      outline: 'none'
                    }
                  }}
                >
                  <Tooltip
                    placement="top"
                    arrow
                    title={showProductForm ? t('Close') : t('Add new product')}
                  >
                    {showProductForm ? (
                      <CloseTwoToneIcon fontSize="small" />
                    ) : (
                      <Typography
                        variant="button"
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                      >
                        Add new
                        <AddTwoToneIcon fontSize="small" />
                      </Typography>
                    )}
                  </Tooltip>
                </Button>
              }
            >
              <InstagramProductList
                postId={currentPost.id}
                products={currentPost.products}
                showForm={showProductForm}
                editTrigered={() => handleToggleForm()}
              />
            </CardInformations>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InstagramPost2;
