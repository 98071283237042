import React, { useEffect, useState } from 'react';
import {
  // Grid,
  styled,
  Box,
  Button,
  CircularProgress,
  alpha,
  TablePagination
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import InstagramPost from './InstagramPost';
import { withRetryOnAuthFailure } from 'src/utils/axios';
import SearchSection from 'src/components/Generic/SearchSection';
import FilterSection from 'src/components/Generic/FilterSection';
import { constructSearchURL } from 'src/utils/helper';
import {
  fetchPostFromInstagram,
  searchAllPosts
} from 'src/utils/instagram-service';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import InstagramPost2 from './InstagramPost2';

const ChatTopBar = styled(Box)(
  ({ theme }) => `
  background-color: ${alpha(theme.header.background, 0.95)};
  border-radius: ${theme.general.borderRadiusLg};
  border: 1px solid ${theme.colors.alpha.black[10]};
  padding-left: ${theme.spacing(4)};
  padding-right: ${theme.spacing(4)};
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
);

const InstagramPostList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchMetadta, setSearchMetadta] = useState({});
  const [paginationMetadata, setPagiantionMetadata] = useState({});
  const [searchData, setSearchData] = useState({
    value: null,
    search_field: null,
    search_type: null,
    from: null,
    to: null
  });
  const [orderByData, setOrderByData] = useState({
    order_by_field: null,
    order_by: null
  });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const { botId } = useParams();

  const handleSearchCange = (data) => {
    if (!(data.search_type === null && searchData.search_type === null)) {
      setSearchData(data);
      setOrderByData({ order_by_field: null, order_by: null });
    }
  };

  const handleFilterChange = (data) => {
    setOrderByData(data);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const getFilesWithQueryParams = () => {
    setLoading(true);
    const query = constructSearchURL({
      from: searchData.from,
      orderBy: orderByData.order_by,
      orderByField: orderByData.order_by_field,
      page: page,
      pageSize: limit,
      searchField: searchData.search_field,
      searchType: searchData.search_type,
      to: searchData.to,
      value: searchData.value
    });
    withRetryOnAuthFailure(searchAllPosts, query, botId)
      .then((response) => {
        setPosts(response.results);
        setPagiantionMetadata(response.metadata);
        setSearchMetadta(response.metadata.search_metadata);
      })
      .catch((error) => {
        toast.error(t('Failed to fetch posts'));
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFetchInstagramPosts = () => {
    setFetchLoading(true);
    withRetryOnAuthFailure(fetchPostFromInstagram, botId)
      .then((response) => {
        toast.success(
          t('Your Instagram posts have been fetched successfully!')
        );
        // add new posts to the existing posts
        setPosts([...response.data.data, ...posts]);
      })
      .catch((error) => {
        // handle 504 Gateway Time-out and make sure that user know that his request is still processing and he should wait
        if (error.response && error.response.status === 504) {
          toast.info(
            t(
              'Your request is taking longer than expected. Please hang tight while we continue to process your request.'
            )
          );
        } else {
          toast.error(t('Failed to fetch Instagram posts'));
        }
        console.log(error);
      })
      .finally(() => {
        setFetchLoading(false);
      });
  };

  useEffect(() => {
    getFilesWithQueryParams();
  }, [searchData, page, limit, orderByData]);

  return (
    <>
      <ChatTopBar>
        <Box flex={1}>
          <Box
            sx={{
              mb: { xs: 2, md: 0 }
            }}
          >
            <SearchSection
              metadata={searchMetadta}
              onSubmited={handleSearchCange}
            />
          </Box>
        </Box>
        <Button
          style={{ marginLeft: 'auto' }}
          variant="outlined"
          disabled={fetchLoading}
          startIcon={
            fetchLoading ? (
              <CircularProgress size={20} />
            ) : (
              <ReplayRoundedIcon />
            )
          }
          onClick={() => handleFetchInstagramPosts()}
        >
          {t('Fetch Instagram Media')}
        </Button>
      </ChatTopBar>
      <ChatTopBar
        sx={{
          backgroundColor: 'transparent',
          border: 'none'
        }}
      >
        <Box flex={1}>{/* */}</Box>
        <Box display="flex" justifyContent="flex-end">
          <FilterSection
            metadata={searchMetadta}
            onSubmited={handleFilterChange}
          />
        </Box>
      </ChatTopBar>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress size={64} disableShrink thickness={3} />
        </Box>
      )}
      {posts?.length > 0 &&
        posts?.map((post) => <InstagramPost2 key={post.id} post={post} />)}
      {posts?.length > 0 && (
        <Box p={2} mt={4} pr={10}>
          <TablePagination
            component="div"
            count={paginationMetadata.total_items || 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 15]}
          />
        </Box>
      )}
    </>
  );
};

export default InstagramPostList;
