import React, { useState } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Box
} from '@mui/material';

const TermsOfServicePage = () => {
  const [supportEmail] = useState('support@zenithchat.co');

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            mb: 2,
            borderBottom: 1,
            borderColor: 'primary.main',
            paddingBottom: 2,
            textAlign: 'center'
          }}
        >
          ZenithChat Chatbot Terms of Service Overview
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={{ lineHeight: '1.3', marginTop: '2rem' }}
        >
          This document outlines the terms of service for ZenithChat Chatbot. By
          using the service, you agree to the terms and conditions outlined in
          this document. Please read the terms carefully before using the
          service.
        </Typography>
        <List>
          <ListItem button component="a" href="#acceptance">
            <ListItemText primary="Acceptance of Terms" />
          </ListItem>
          <ListItem button component="a" href="#service-description">
            <ListItemText primary="Description of Service" />
          </ListItem>
          <ListItem button component="a" href="#user-accounts">
            <ListItemText primary="User Accounts and Responsibilities" />
          </ListItem>
          <ListItem button component="a" href="#content-ownership">
            <ListItemText primary="Content Ownership and Intellectual Property" />
          </ListItem>
          <ListItem button component="a" href="#use-of-service">
            <ListItemText primary="Use of Service" />
          </ListItem>
          <ListItem button component="a" href="#third-party-integrations">
            <ListItemText primary="Integration with Third-party Services" />
          </ListItem>
          <ListItem button component="a" href="#data-handling">
            <ListItemText primary="Data Handling and Privacy" />
          </ListItem>
          <ListItem button component="a" href="#service-availability">
            <ListItemText primary="Service Availability" />
          </ListItem>
          <ListItem button component="a" href="#termination">
            <ListItemText primary="Termination and Suspension" />
          </ListItem>
          <ListItem button component="a" href="#warranties">
            <ListItemText primary="Warranties and Disclaimers" />
          </ListItem>
          <ListItem button component="a" href="#indemnification">
            <ListItemText primary="Indemnification" />
          </ListItem>
          <ListItem button component="a" href="#changes-to-terms">
            <ListItemText primary="Changes to Terms and Conditions" />
          </ListItem>
          <ListItem button component="a" href="#law-and-dispute">
            <ListItemText primary="Governing Law and Dispute Resolution" />
          </ListItem>
          <ListItem button component="a" href="#modification-and-access">
            <ListItemText primary="Modification and Access to the Service" />
          </ListItem>
          <ListItem button component="a" href="#contact-info">
            <ListItemText primary="Contact Information" />
          </ListItem>
        </List>
        <Box my={4}>
          <Typography variant="h4" gutterBottom id="acceptance">
            Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing or using the ZenithChat Chatbot, you acknowledge that you
            have read, understood, and agree to be bound by these Terms of
            Service. If you do not agree with any part of the terms, you may not
            use our services.
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="h4" gutterBottom id="service-description">
            Description of Service
          </Typography>
          <Typography variant="body1" paragraph>
            ZenithChat Chatbot provides a web application that automates responses
            to Instagram DMs for business accounts. The service allows users to
            connect their Instagram account to our platform, enhance their
            knowledge base, and enable the AI-driven chatbot to handle messaging
            on their behalf.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="user-accounts">
            User Accounts and Responsibilities
          </Typography>
          <Typography variant="body1" paragraph>
            Users are responsible for maintaining the confidentiality of their
            account credentials and for all activities under their account. You
            must provide accurate and complete information and keep it up to
            date. Misuse or unauthorized use of your account must be immediately
            reported to our support team.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="content-ownership">
            Content Ownership and Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            Users retain ownership of the content they input into ZenithChat
            Chatbot. However, by using the service, you grant us a worldwide,
            non-exclusive, royalty-free license to use, reproduce, adapt,
            publish, and display content generated by the chatbot for operating
            and improving our services.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="use-of-service">
            Use of Service
          </Typography>
          <Typography variant="body1" paragraph>
            The services must be used only for lawful purposes in accordance
            with these terms and any applicable laws. Prohibited activities
            include any form of harassment, unauthorized advertising, and
            infringing on others’ intellectual property.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="third-party-integrations">
            Integration with Third-party Services
          </Typography>
          <Typography variant="body1" paragraph>
            ZenithChat Chatbot integrates with Instagram, which is governed by its
            own terms and privacy policy. You are responsible for ensuring that
            your use of our services complies with the terms and conditions of
            Instagram.
            <br />
            ZenithChat Chatbot may also integrate with other third-party services
            to provide additional features. By using these integrations, you are
            bound by the terms and policies of the respective third-party
            services.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="data-handling">
            Data Handling and Privacy
          </Typography>
          <Typography variant="body1" paragraph>
            Your privacy is important to us. Our Privacy Policy, which details
            how we collect, use, and protect your data, is incorporated into
            these Terms of Service by reference. Please review it to understand
            our practices.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="service-availability">
            Service Availability
          </Typography>
          <Typography variant="body1" paragraph>
            We aim to provide a reliable and available service, but do not
            guarantee uninterrupted service. Scheduled maintenance, unforeseen
            downtime, and outages may occur, and we will endeavor to provide
            timely information about such issues.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="termination">
            Termination and Suspension
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to suspend or terminate your account if you
            violate these Terms of Service, engage in illegal activities, or if
            continued use may cause harm to our service or other users.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="warranties">
            Warranties and Disclaimers
          </Typography>
          <Typography variant="body1" paragraph>
            ZenithChat Chatbot is provided on an "as is" and "as available" basis
            without any warranties, express or implied, including but not
            limited to the performance of the chatbot. We disclaim all
            warranties to the maximum extent permitted by law. You represent and
            warrant that you have the authority to enter into these Terms of
            Service and that your use of the service will not violate any
            applicable laws or regulations. You also represent and warrant that
            you are at least 18 years of age.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="indemnification">
            Indemnification
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to defend, indemnify, and hold harmless ZenithChat Chatbot
            and its affiliates, officers, agents, and employees from any claims,
            damages, liabilities, and expenses arising from your use of the
            service, your violation of these Terms, or the infringement by you
            or made under your account of any intellectual property or other
            right of any person or entity.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="changes-to-terms">
            Changes to Terms and Conditions
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms may be amended or updated from time to time to reflect
            changes in our practices, technology, legal requirements, and other
            factors. We will provide notice of these changes by updating the
            date at the top of the Terms of Service.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="law-and-dispute">
            Governing Law and Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms shall be governed by and construed in accordance with
            the laws of the jurisdiction in which our company is registered,
            without regard to its conflict of law principles. Disputes arising
            under these Terms will be resolved in the state or federal courts
            located in that jurisdiction.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="modification-and-access">
            Modification and Access to the Service
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify or discontinue the service at any
            time without notice. We are not liable to you or any third party for
            any modification, suspension, or discontinuation of the service.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="contact-info">
            Contact Information
          </Typography>
          <Typography variant="body1" paragraph>
            For any questions, concerns, or disputes, you may contact us at{' '}
            <a
              style={{
                color: 'inherit',
                textDecoration: 'underline'
              }}
              href={`mailto:${supportEmail}`}
            >
              {supportEmail}
            </a>
            . We are committed to resolving any concerns through our support
            channels.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsOfServicePage;
