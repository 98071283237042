import { PureLightTheme } from './schemes/PureLightTheme';
import { NebulaFighterTheme } from './schemes/NebulaFighterTheme';

const themeMap = {
  PureLightTheme,
  NebulaFighterTheme,
};

export function themeCreator(theme) {
  return themeMap[theme];
}
