import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import logo from '../../images/ZenithChatLogo.svg';
import logoDark from '../../images/ZenithChatLogoDark.svg';
import React, { useEffect, useState } from 'react';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
      color: ${theme.palette.text.primary};
      padding: ${theme.spacing(0, 1, 0, 0)};
      display: flex;
      justify-content:center;
      align-items:center;
      text-decoration: none;
`
);

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
      padding-left: ${theme.spacing(0)};`
);

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(20)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const [logoPath, setLogoPath] = useState('/');
  const theme = useTheme()

  useEffect(() => {
    const chatbotId = localStorage.getItem('REACT_APP_CHATBOT_ID');
    if (chatbotId) {
      setLogoPath(`/${chatbotId}/Jobs`);
    } else {
      setLogoPath('/');
    }
  }, [localStorage.getItem('REACT_APP_CHATBOT_ID')]);

  return (
    // navigate if local storage contains REACT_APP_CHATBOT_ID navigate to /REACT_APP_CHATBOT_ID value/ otherwise navigate to /
    <LogoWrapper to={logoPath} style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      {theme.palette.mode === 'dark' ? (
        <img src={logoDark} alt="ZenithChat" style={{ height: '100%', width: 'auto' }} />
      ) : (
        <img src={logo} alt="ZenithChat" style={{ height: '100%', width: 'auto' }} />
      )}
      <Box
        component="span"
        sx={{
          display: { xs: 'none', sm: 'inline-block' },
        }}
      >
        {/* <LogoTextWrapper>
        <LogoText>ZenithChat</LogoText>
      </LogoTextWrapper> */}
      </Box>
    </LogoWrapper>
  );
}

export default Logo;
