import React, { useState } from 'react';
import { Typography, Button, TextField, Box } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useTranslation } from 'react-i18next';

const CardEditableAttribute = ({ editableAttributes, onSave }) => {
  const { t } = useTranslation();
  const [editStates, setEditStates] = useState(
    editableAttributes.reduce((acc, attr) => {
      acc[attr.name] = { isEditing: false, value: attr.value };
      return acc;
    }, {})
  );

  const handleEdit = (name) => {
    setEditStates({
      ...editStates,
      [name]: { ...editStates[name], isEditing: true }
    });
  };

  const handleChange = (name, newValue) => {
    setEditStates({
      ...editStates,
      [name]: { ...editStates[name], value: newValue }
    });
  };

  const handleSave = (attribute) => {
    if (onSave) {
      onSave(attribute.attribute, editStates[attribute.name].value);
    }
    setEditStates({
      ...editStates,
      [attribute.name]: { ...editStates[attribute.name], isEditing: false }
    });
  };

  const handleCancel = (name, originalValue) => {
    setEditStates({
      ...editStates,
      [name]: { isEditing: false, value: originalValue }
    });
  };

  return (
    editableAttributes &&
    editableAttributes.map((attribute, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 1,
          border: editStates[attribute.name]?.isEditing
            ? 'none'
            : '1px solid #ccc',
          padding: 2,
          borderRadius: 1,
          position: 'relative',
          //   marginTop: editStates[attribute.name]?.isEditing ? 0 : 1,
          marginTop: 1,
          height: 280,
          maxWidth: '100%',
          overflow: 'hidden' // Ensures text overflow is hidden by default
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 10px'
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {t(attribute.name)}
          </Typography>
          {!editStates[attribute.name]?.isEditing ? (
            <Button
              startIcon={<EditTwoToneIcon />}
              size="small"
              onClick={() => handleEdit(attribute.name)}
            >
              {t('Edit')}
            </Button>
          ) : (
            <Box>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleSave(attribute)}
                sx={{ marginRight: 1 }}
              >
                {t('Save')}
              </Button>
              <Button
                size="small"
                onClick={() => handleCancel(attribute.name, attribute.value)}
              >
                {t('Cancel')}
              </Button>
            </Box>
          )}
        </Box>
        {!editStates[attribute.name]?.isEditing ? (
          <Typography
            variant="body1"
            component="div"
            sx={{
              width: '100%',
              overflow: 'auto', // Allows scrolling if content overflows
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              marginTop: 1,
              maxWidth: '100%',
              maxHeight: '100%', // Ensures the text container does not exceed its parent's height
              //   add border to the text container to make it more visible and to looks like editable text
              border: '1px solid #ccc',
              padding: 1,
              borderRadius: 1
            }}
          >
            {editStates[attribute.name]?.value}
          </Typography>
        ) : (
          <TextField
            fullWidth
            multiline
            rows={9}
            value={editStates[attribute.name]?.value}
            onChange={(e) => handleChange(attribute.name, e.target.value)}
            sx={{
              marginTop: '10px',
              resize: 'vertical',
              flexGrow: 1,
              maxWidth: '100%'
            }}
            inputProps={{
              style: {
                overflow: 'auto'
              }
            }}
          />
        )}
      </Box>
    ))
  );
};

export default CardEditableAttribute;
