import { Box, Card, Typography, styled, Link } from '@mui/material';
import ShieldLogo from '../../images/icons/shieldIcon.png';

// const FooterWrapper = styled(Card)(
//   ({ theme }) => `
//     border-radius: 0;
//     height: ${theme.footer.height};
// `
// );
const FooterWrapper = styled(Card)(
  ({ theme }) => `
    border-radius: 0;
    max-height: 150px;
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; {new Date().getFullYear()} - ZenithChat
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <img
            src={ShieldLogo}
            alt="shield"
            style={{
              width: '32px',
              height: '32px',
              marginRight: '8px' // Adds a margin to the right of the icon for spacing
            }}
          />
          <Typography variant="subtitle1">
            <Link href="/privacy-policy" color="inherit" underline="hover">
              Privacy Policy
            </Link>
            {' | '}
            <Link href="/terms-of-service" color="inherit" underline="hover">
              Terms of Service
            </Link>
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
