import React from 'react';
import { useForm } from 'react-hook-form';
import {
  MenuItem,
  Button,
  TextField,
  Box,
  Stack,
  Divider,
  Grid,
  Autocomplete
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import { useTranslation } from 'react-i18next';
import { getSearchTypeLabel } from 'src/utils/helper';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useAutoAnimate } from '@formkit/auto-animate/react';

function SearchSection({ metadata, onSubmited }) {
  const { t } = useTranslation();
  const [parent] = useAutoAnimate();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      value: null,
      from: null,
      to: null,
      search_type: null,
      search_field: null
    },
    mode: 'onSubmit'
  });

  const selectedField = watch('search_field');
  const selectedSearchType = watch('search_type');

  function formatValue(value) {
    if (value instanceof Date) {
      let isoString = value.toISOString();
      return isoString.split('T')[0];
    }
    return value;
  }

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      to: formatValue(data.to),
      from: formatValue(data.from),
      value: formatValue(data.value)
    };
    onSubmited(formattedData);
  };

  const renderInputBasedOnType = (type, searchType) => {
    switch (type) {
      case 'uuid':
        return <></>;
      case 'string':
        return (
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onBlur={field.onBlur}
                onChange={field.onChange}
                fullWidth
                variant="outlined"
                label={t('Value here')}
                placeholder={t('Value')}
                required
              />
            )}
          />
        );
      case 'integer':
        if (searchType === 'range') {
          return (
            <>
              <Controller
                name="from"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    variant="outlined"
                    label={t('From')}
                    placeholder={t('From')}
                    type="number"
                    required
                  />
                )}
              />

              <Controller
                name="to"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    variant="outlined"
                    label={t('To')}
                    placeholder={t('To')}
                    type="number"
                  />
                )}
              />
            </>
          );
        }
        return (
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                onBlur={field.onBlur}
                onChange={field.onChange}
                variant="outlined"
                label={t('Value')}
                placeholder={t('Value here')}
                type="number"
                required
              />
            )}
          />
        );
      case 'datetime':
        if (searchType === 'range') {
          return (
            <>
              <Controller
                name="from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    fullWidth
                    {...field}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    label={t('From')}
                    placeholder={t('From')}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('From')}
                        placeholder={t('From')}
                        fullWidth
                        variant="outlined"
                        required
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    fullWidth
                    {...field}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    label={t('To')}
                    placeholder={t('To')}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    renderInput={(params) => (
                      <TextField
                        label={t('To')}
                        placeholder={t('To')}
                        {...params}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </>
          );
        }
        return (
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <DatePicker
                fullWidth
                {...field}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                label={t('Value')}
                placeholder={t('Value here')}
                inputFormat="yyyy-MM-dd"
                mask="____-__-__"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Value')}
                    placeholder={t('Value here')}
                    fullWidth
                    variant="outlined"
                    required
                  />
                )}
              />
            )}
          />
        );
      case 'enum':
        return (
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <TextField select {...field} fullWidth required>
                {metadata[selectedField].enum_choices.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={parent}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Stack
            sx={{
              p: 2,
              flex: 1
            }}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Controller
              name="search_field"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  sx={{ width: '100%' }}
                  value={field.value}
                  onChange={(_, data) => {
                    field.onChange(data);
                    setValue('search_type', null);
                    setValue('value', '');
                    setValue('from', '');
                    setValue('to', '');
                    if (data === null) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  limitTags={1}
                  options={Object.keys(metadata)}
                  getOptionLabel={(option) => t(metadata[option].description)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      variant="outlined"
                      label={t('Select search field')}
                      placeholder={t('Select field...')}
                      required
                    />
                  )}
                />
              )}
            />

            {selectedField && (
              <Controller
                name="search_type"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    sx={{ width: '100%' }}
                    value={field.value}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setValue('value', '');
                      setValue('from', '');
                      setValue('to', '');
                    }}
                    limitTags={1}
                    defaultValue={null}
                    options={metadata[selectedField].search_choices}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) =>
                      getSearchTypeLabel(option)
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        variant="outlined"
                        label={t('Search type')}
                        placeholder={t('Select search type...')}
                        required
                      />
                    )}
                  />
                )}
              />
            )}

            {selectedField &&
              selectedSearchType &&
              renderInputBasedOnType(
                metadata[selectedField].type,
                selectedSearchType
              )}

            <Button
              variant="outlined"
              startIcon={<SearchTwoToneIcon />}
              type="submit"
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%'
                },
                maxWidth: {
                  lg: '120px',
                  md: '120px'
                }
              }}
            >
              {t('Search')}
            </Button>
          </Stack>
        </Box>
      </Grid>
    </form>
  );
}

export default SearchSection;

SearchSection.propTypes = {
  metadata: PropTypes.object,
  onSubmited: PropTypes.func
};
