export const documentTypes = [
  { value: 'DOCUMENT', label: 'Document' },
  { value: 'BOOK', label: 'Book' },
  { value: 'DISSERTATION', label: 'Dissertation' },
  { value: 'AUDIO', label: 'Audio' },
  { value: 'REPORT', label: 'Report' },
  { value: 'OTHER', label: 'Other' }
];

export const splitTypes = [
  { value: 'RECURSIVE', label: 'Recursive' },
  { value: 'FINE', label: 'Fine' }
];

export const documentStatus = [
  { value: 'COMPLETE', label: 'Complete' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'ERROR', label: 'Error' },
  { value: 'CANCELED', label: 'Canceled' }
];
