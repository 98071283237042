import { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../utils/helper';
import { documentStatus } from 'src/data/data.ts';

import {
  Avatar,
  Box,
  Checkbox,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Button,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  styled,
  Grid,
  CircularProgress
} from '@mui/material';
import {
  deleteAudioFile,
  searchAllAudioFiles
} from '../../utils/audio-service';
import { useNavigate } from 'react-router';
import { withRetryOnAuthFailure } from '../../utils/axios';
import { constructSearchURL, getStatusLabel } from 'src/utils/helper';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useTranslation } from 'react-i18next';
import BulkActions from '../Files/BulkActions';
import SearchSection from 'src/components/Generic/SearchSection';
import FilterSection from 'src/components/Generic/FilterSection';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import { useParams } from 'react-router';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
       background: ${theme.colors.error.main};
       color: ${theme.palette.error.contrastText};
  
       &:hover {
          background: ${theme.colors.error.dark};
       }
      `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AudioFileTable = ({
  status = documentStatus[0].value,
  page,
  limit,
  setPage,
  setLimit
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedItems, setSelectedItems] = useState([]);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [paginationMetadata, setPagiantionMetadata] = useState({});
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState({
    value: null,
    search_field: null,
    search_type: null,
    from: null,
    to: null
  });
  const [orderByData, setOrderByData] = useState({
    order_by_field: null,
    order_by: null
  });

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeItems =
    selectedItems.length > 0 && selectedItems.length < paginatedItems?.length;
  const selectedAllItems = selectedItems.length === paginatedItems?.length;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [documentForDelete, setDocumentForDelete] = useState('');
  const [searchMetadta, setSearchMetadta] = useState({});
  const [isSending, setIsSending] = useState(false);
  const { botId } = useParams();

  const getAudioWithQueryParams = () => {
    setLoading(true);
    const query = constructSearchURL({
      from: searchData.from,
      orderBy: orderByData.order_by,
      orderByField: orderByData.order_by_field,
      page: page,
      pageSize: limit,
      searchField: searchData.search_field,
      searchType: searchData.search_type,
      status: status,
      to: searchData.to,
      value: searchData.value
    });
    withRetryOnAuthFailure(searchAllAudioFiles, query, botId)
      .then((res) => {
        setPaginatedItems(res.results);
        setPagiantionMetadata(res.metadata);
        setSearchMetadta(res.metadata.search_metadata);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAudioWithQueryParams();
  }, [searchData, page, limit, orderByData, status]);

  const handleSelectAllItems = (event) => {
    setSelectedItems(
      event.target.checked ? paginatedItems.map((item) => item.id) : []
    );
  };

  const handleSelectOneItem = (event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [...prevSelected, itemId]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== itemId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const handleConfirmDelete = (documentId) => {
    setOpenConfirmDelete(true);
    setDocumentForDelete(documentId);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setDocumentForDelete('');
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);
    setIsSending(true);
    toast.promise(withRetryOnAuthFailure(deleteAudioFile, documentForDelete, botId), {
      pending: {
        render() {
          return 'Deleting audio file...';
        },
        icon: true
      },
      success: {
        render({ data }) {
          setIsSending(false);
          setDocumentForDelete('');
          getAudioWithQueryParams();
          return data.message;
        },
        icon: true
      },
      error: {
        render({ data }) {
          setIsSending(false);
          return `Failed to delete document: ${data.message}`;
        },
        icon: true
      }
    });
  };

  const handleSearchCange = (data) => {
    if (!(data.search_type === null && searchData.search_type === null)) {
      setSearchData(data);
      setOrderByData({
        order_by: null,
        order_by_field: null
      });
    }
  };

  const handleFilterCange = (data) => {
    setOrderByData(data);
    setPage(0);
  };

  return (
    <>
      <Box display={'flex'} alignItems={'center'} mb={3}>
        {selectedBulkActions && (
          <Box flex={1} p={2}>
            <BulkActions />
          </Box>
        )}
        {!selectedBulkActions && (
          <Box flex={1} py={2}>
            <Box
              sx={{
                mb: { xs: 2, md: 0 }
              }}
            >
              <SearchSection
                metadata={searchMetadta}
                onSubmited={handleSearchCange}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        p={1}
      >
        <FilterSection
          metadata={searchMetadta}
          onSubmited={handleFilterCange}
        />
      </Box>
      <Divider />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress size={64} disableShrink thickness={3} />
        </Box>
      ) : paginatedItems?.length === 0 ? (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any documents matching your search criteria")}
        </Typography>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllItems}
                      indeterminate={selectedSomeItems}
                      onChange={handleSelectAllItems}
                    />
                  </TableCell>
                  <TableCell>{t('Title')}</TableCell>
                  {status !== documentStatus[2].value && (
                    <TableCell align="center">{t('Status')}</TableCell>
                  )}
                  <TableCell align="center">{t('Upload date')}</TableCell>
                  {status !== documentStatus[2].value && (
                    <TableCell align="center">{t('Release date')}</TableCell>
                  )}
                  <TableCell align="center">{t('Document type')}</TableCell>
                  {status !== documentStatus[2].value ? (
                    <>
                      <TableCell align="center">{t('Chunks')}</TableCell>
                      <TableCell align="center">{t('Size')}</TableCell>
                    </>
                  ) : (
                    <TableCell align="center">{t('Metadata')}</TableCell>
                  )}

                  {/* <TableCell
                    align={
                      status !== documentStatus[2].value ? 'center' : 'left'
                    }
                  >
                    {t('Metadata')}
                  </TableCell> */}
                  {status === documentStatus[2].value && (
                    <TableCell align="center">{t('Message')}</TableCell>
                  )}
                  {status !== documentStatus[1].value && (
                    <TableCell align="center">{t('Actions')}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedItems?.map((item) => {
                  const isItemSelected = selectedItems.includes(item.id);
                  return (
                    <TableRow hover key={item.id} selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) =>
                            handleSelectOneItem(event, item.id)
                          }
                          value={isItemSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Box
                            component="div"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {item?.original_url ? (
                              <Tooltip title={item?.original_url} arrow>
                                <Typography variant="subtitle2">
                                  <a
                                    href={ensureFullURL(item?.original_url)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: 'underline'
                                    }}
                                  >
                                    {item.title}
                                  </a>
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography variant="subtitle2">
                                {item.title}
                              </Typography>
                            )}
                            <Typography variant="subtitle2">
                              {status === documentStatus[2].value &&
                                getStatusLabel(item._status)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      {status !== documentStatus[2].value && (
                        <TableCell align="center">
                          {getStatusLabel(item._status)}
                        </TableCell>
                      )}
                      <TableCell align="center">
                        {item.start_at ? formatDate(item.start_at) : 'Unknown'}
                      </TableCell>
                      {status !== documentStatus[2].value && (
                        <TableCell align="center">
                          <Typography noWrap variant="subtitle2">
                            {item.release_date ? item.release_date : 'Unknown'}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell align="center">
                        {item.document_type ? item.document_type : 'Document'}
                      </TableCell>

                      {status !== documentStatus[2].value ? (
                        <>
                          <TableCell align="center">
                            <Grid
                              item
                              {...(isSmallScreen ? { container: true } : {})}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {item.chunks ? item.chunks : 0}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell align="center">
                            <Grid
                              item
                              {...(isSmallScreen ? { container: true } : {})}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {item.file_size
                                  ? `${Math.ceil(
                                    item.file_size / 1024 / 1024
                                  )} ${t('MB')}`
                                  : t('Unknown')}
                              </Typography>
                            </Grid>
                          </TableCell>
                        </>
                      ) : (
                        <TableCell align="center">
                          <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="subtitle2" noWrap>
                              CHUNKS:
                            </Typography>
                            <Typography variant="subtitle2" noWrap>
                              {item.chunks ? item.chunks : 0}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="subtitle2" noWrap>
                              SIZE:
                            </Typography>
                            <Typography variant="subtitle2" noWrap>
                              {item.file_size
                                ? `${Math.ceil(
                                  item.file_size / 1024 / 1024
                                )} ${t('MB')}`
                                : t('Unknown')}
                            </Typography>
                          </Grid>
                        </TableCell>
                      )}
                      {status === documentStatus[2].value && (
                        <TableCell align="center">
                          <Typography>
                            {item._message ? item._message : 'No message'}
                          </Typography>
                        </TableCell>
                      )}
                      {status !== documentStatus[1].value && (
                        <TableCell align="center">
                          <Typography noWrap>
                            <Tooltip title={t('View Chunks')} arrow>
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/${localStorage.getItem(
                                      'REACT_APP_CHATBOT_ID'
                                    )}/Jobs/Audio/chunks/${item.id}/`
                                  )
                                }
                                color="primary"
                                disabled={isSending}
                              >
                                <LaunchTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Delete')} arrow>
                              <IconButton
                                onClick={() => handleConfirmDelete(item.id)}
                                color="primary"
                                disabled={isSending}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={paginationMetadata.total_items || 0}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Do you really want to delete this item')}?
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            {t("You won't be able to revert after deletion")}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={() => handleDeleteCompleted()}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

const ensureFullURL = (url) => {
  if (!/^http(s?):\/\//.test(url)) {
    return 'https://' + url;
  }
  return url;
};

AudioFileTable.propTypes = {
  items: PropTypes.array.isRequired
};

AudioFileTable.defaultProps = {
  items: []
};

export default AudioFileTable;
