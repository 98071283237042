import { lazy } from 'react';
import MainLayout from '../pages/MainLayout';
import Authenticated from '../components/Authenticated';
import { Navigate } from 'react-router-dom';
import baseRoutes from './base';
import BaseLayout from '../pages/BaseLayout';
// import SemanticSearchBox from 'src/components/SemanticSearch/SemanticSearchBox';
import DocumentsList from 'src/components/Files/DocumentsList';
import AudioList from 'src/components/Audio/AudioList';
import TextList from 'src/components/TextContent/TextList';
import NewTextForm from 'src/components/TextContent/NewTextForm';
import InstagramPostList from 'src/components/InstagramPost/InstagramPostList';
import StatusComingSoon from 'src/content/pages/Status/ComingSoon';
// import FacebookSDKPage from 'src/pages/FacebookSDKPage';
// import FacebookSDKPage2 from 'src/pages/FacebookSDKPage2';

const FilesPage = lazy(() => import('src/pages/FilesPage'));
const AudioPage = lazy(() => import('src/pages/AudioPage'));
const TextPage = lazy(() => import('src/pages/TextPage'));
// const SemanticSearchPage = lazy(() => import('src/pages/SemanticSearchPage'));
const LoginPage = lazy(() => import('src/pages/LoginPage'));
const RegisterPage = lazy(() => import('src/pages/RegisterPage'));
const ChatbotEditPage = lazy(() => import('src/pages/ChatbotEditPage'));
const AudioChunksPage = lazy(() =>
  import('src/components/Audio/AudioChunksPage')
);
const NewAudioPage = lazy(() => import('src/components/Audio/NewAudioPage'));
const NewBookPage = lazy(() => import('src/components/Files/NewFilePage'));
const InstagramPostsPage = lazy(() => import('src/pages/InstagramPostsPage'));
const ChatHistoryPage = lazy(() => import('src/pages/ChatHistoryPage'));
const ProfileEditPage = lazy(() => import('src/pages/ProfileEditPage'));
const FacebookAccountsPage = lazy(() =>
  import('src/pages/FacebookAccountsPage')
);

const router = [
  {
    path: '/',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: '/:botId/Jobs',
    element: (
      <Authenticated>
        <MainLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={`InstagramContent`} replace />
      },
      {
        path: 'Files',
        element: <FilesPage />,
        children: [
          {
            path: '',
            element: <DocumentsList />
          },
          {
            path: 'New',
            element: <NewBookPage />
          }
        ]
      },
      {
        path: 'Audio',
        element: <AudioPage />,
        children: [
          {
            path: '',
            element: <AudioList />
          },
          {
            path: 'Chunks/:audioId',
            element: <AudioChunksPage />
          },
          {
            path: 'New',
            element: <NewAudioPage />
          }
        ]
      },
      {
        path: 'Text',
        element: <TextPage />,
        children: [
          {
            path: '',
            element: <TextList />
          },
          {
            path: 'New',
            element: <NewTextForm />
          }
        ]
      },
      {
        path: 'InstagramContent',
        element: <InstagramPostsPage />,
        children: [
          {
            path: '',
            element: <InstagramPostList />
          },
          {
            path: 'New',
            element: <StatusComingSoon />
          }
        ]
      }
    ]
  },
  // {
  //   path: '/:botId/playground',
  //   element: (
  //     <Authenticated>
  //       <MainLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="semantic-search" replace />
  //     },
  //     {
  //       path: 'semantic-search',
  //       element: <SemanticSearchPage />,
  //       children: [
  //         {
  //           path: '',
  //           element: <SemanticSearchBox />
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '/Facebook',
    element: (
      <Authenticated>
        <MainLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <FacebookAccountsPage />
      },
      {
        path: 'Accounts',
        element: <FacebookAccountsPage />
      }
      // {
      //   path: 'sdk',
      //   element: <FacebookSDKPage />
      // },
      // {
      //   path: 'sdk2',
      //   element: <FacebookSDKPage2 />
      // }
    ]
  },
  {
    path: '/:botId/Facebook',
    element: (
      <Authenticated>
        <MainLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <FacebookAccountsPage />
      },
      {
        path: 'Accounts',
        element: <FacebookAccountsPage />
      }
    ]
  },
  {
    path: '/:botId/Instagram',
    element: (
      <Authenticated>
        <MainLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="History" replace />
      },
      {
        path: 'History',
        element: <ChatHistoryPage />,
        children: [
          {
            path: '',
            element: <ChatHistoryPage />
          },
          {
            path: ':sessionId',
            element: <ChatHistoryPage />
          }
        ]
      }
    ]
  },
  {
    path: 'Login',
    element: <LoginPage />
  },
  {
    path: 'Register',
    element: <RegisterPage />
  },
  {
    path: '/:botId/Settings',
    element: (
      <Authenticated>
        <MainLayout />
      </Authenticated>
    ),
    children: [
      {
        path: 'Chatbot',
        element: <ChatbotEditPage />
      },
      {
        path: 'Profile',
        element: <ProfileEditPage />
      }
    ]
  }
];

export default router;
