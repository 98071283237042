import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { withRetryOnAuthFailure } from 'src/utils/axios';

const NewInstagramProduct = ({ product, onSubmitHandler }) => {
  const { t } = useTranslation();
  const { control, reset, handleSubmit, getValues } = useForm({
    defaultValues: {
      name: product?.name || '',
      url: product?.url || null,
      metadata: product?.metadata || ''
    },
    mode: 'onBlur'
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearForm = () => {
    reset({
      name: '',
      url: null,
      metadata: ''
    });
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const dto = {
      id: product?.id || null,
      name: getValues('name'),
      url: getValues('url'),
      metadata: getValues('metadata')
    };

    const promise = new Promise((resolve, reject) => {
      withRetryOnAuthFailure(() => onSubmitHandler(dto))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

    toast.promise(promise, {
      pending: {
        render() {
          return t('Adding product...');
        }
      },
      success: {
        render() {
          setIsSubmitting(false);
          clearForm();
          return t('Product added successfully');
        }
      },
      error: {
        render() {
          setIsSubmitting(false);
          return t('Failed to add product');
        }
      }
    });
  };

  return (
    <>
      <Divider />
      <Box
        sx={{
          m: 1,
          pt: 1,
          pb: 1,
          position: 'relative',
          transition: 'transform 0.3s ease',
          height: 'auto',
          textAlign: 'center'
        }}
      >
        <Typography variant="h4" gutterBottom mb={2}>
          {product?.id ? t('Edit Product') : t('New Product')}
        </Typography>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box
            py={0.5}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: 40 }} // Reduce the height of the input fields
          >
            <Controller
              name="name"
              control={control}
              rules={{
                required: t('Product name is required'),
                minLength: {
                  value: 3,
                  message: t('Minimum length is 3 characters')
                },
                maxLength: {
                  value: 100,
                  message: t('Maximum length is 100 characters')
                }
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t('Product Name')}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={field.value}
                  sx={{ marginRight: 1 }}
                  size="small" // Reduce the input field size
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="url"
              control={control}
              rules={{
                pattern: {
                  value: /^https?:\/\/.*/,
                  message: t('Invalid URL')
                }
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label={t('URL')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={field.value}
                  onChange={field.onChange}
                  size="small"
                />
              )}
            />
          </Box>
          <Controller
            name="metadata"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                label={t('Metadata')}
                variant="outlined"
                fullWidth
                value={field.value}
                multiline
                rows={3}
                margin="normal"
                onChange={field.onChange}
                size="small" // Reduce the input field size
              />
            )}
          />
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={12} md={4} />
            <Grid item xs={12} sm={6} md={4} container justifyContent="center">
              {' '}
              <Box display="flex" justifyContent="center">
                <Button
                  startIcon={
                    isSubmitting ? <CircularProgress size={20} /> : null
                  }
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {product?.id ? t('Save Changes') : t('Add Product')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Divider />
    </>
  );
};

export default NewInstagramProduct;
