import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Dialog,
  IconButton,
  CircularProgress,
  Chip,
  Button,
  ListItemText,
  Divider,
  ListItem,
  useTheme,
  List,
  styled,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  formatDate,
  getTextStatusCollor,
  getTextStatusCollorText
} from 'src/utils/helper';
import { getTextTypeLabel } from 'src/utils/helper';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { withRetryOnAuthFailure } from 'src/utils/axios';
import { getTextById } from 'src/utils/text-service';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import NewTextForm from './NewTextForm';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import 'src/theme/fade.css';
import { useParams } from 'react-router-dom';

const CardActions = styled(Box)(
  ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(2)};
      top: ${theme.spacing(2)};
      z-index: 7;
    `
);

function TextDetailsModal({ open, Transition, onClose, id, onChange }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [text, setText] = useState(undefined);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [parent] = useAutoAnimate();
  const [edit, setEdit] = useState(false);
  let { botId } = useParams();


  useEffect(() => {
    if (open) {
      withRetryOnAuthFailure(getTextById, id, botId)
        .then((res) => {
          // console.log(res);
          setText(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setEdit(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      scroll="body"
    >
      <Box
        sx={{
          position: 'relative'
        }}
        ref={parent}
      >
        <CardActions>
          <Tooltip arrow placement="top" title={t('Close')}>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </CardActions>

        <Box
          pt={2}
          px={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box maxWidth={'90%'}>
            <Typography variant={isSmallScreen ? 'h4' : 'h2'}>
              {edit ? (
                <>
                  <Tooltip arrow placement="top" title={t('Go back')}>
                    <IconButton
                      onClick={() => {
                        setEdit(false);
                      }}
                      color="primary"
                      sx={{
                        p: 2,
                        mr: 2
                      }}
                    >
                      <ArrowBackTwoToneIcon />
                    </IconButton>
                  </Tooltip>
                  {t('Edit article')}
                </>
              ) : (
                text?.title
              )}
            </Typography>
          </Box>
        </Box>
        <Divider
          sx={{
            my: text && text?.title ? 2 : 6
          }}
        />
        <>
          {edit && <NewTextForm text={text} onChange={onChange} />}

          {!edit && text !== undefined && (
            <>
              <Box
                elevation={0}
                sx={{
                  mx: 'auto',
                  maxWidth: '800px',
                  textAlign: 'center'
                }}
              >
                <Typography
                  variant="h3"
                  color="text.secondary"
                  sx={{ ml: 'auto' }}
                >
                  {t('Metadata')}:
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h4'
                      }}
                      primary={t('Article type') + ':'}
                    />
                    <Typography variant="subtitle2">
                      {' '}
                      {t(getTextTypeLabel(text?.document_type))}
                    </Typography>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h4'
                      }}
                      primary={t('Uploaded on') + ':'}
                    />
                    <Typography variant="subtitle2">
                      {' '}
                      {formatDate(text?.start_at)}
                    </Typography>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h4'
                      }}
                      primary={t('Updated on') + ':'}
                    />
                    <Typography variant="subtitle2">
                      {' '}
                      {formatDate(text?.updated_at)}
                    </Typography>
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h4'
                      }}
                      primary={t('Content length') + ':'}
                    />
                    <Typography variant="subtitle2">
                      {' '}
                      {text?.content_length} {t('characters')}
                    </Typography>
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h4'
                      }}
                      primary={t('Link') + ':'}
                    />
                    {text?.original_url && (
                      <Tooltip title={text?.original_url} arrow>
                        <Typography variant="subtitle2">
                          {' '}
                          <a
                            href={ensureFullURL(text?.original_url)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('follow the link')}
                          </a>
                        </Typography>
                      </Tooltip>
                    )}
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h4'
                      }}
                      primary={t('Status') + ':'}
                    />
                    <Chip
                      sx={{
                        backgroundColor: getTextStatusCollor(
                          text?.status,
                          theme
                        ),
                        color: getTextStatusCollorText(
                          text?.status,
                          theme
                        ),
                        fontWeight: 'bold'
                      }}
                      label={text?.status}
                    />
                  </ListItem>
                </List>
              </Box>
              <Divider
                sx={{
                  mt: 4
                }}
              />
              <Box
                display="flex"
                flexDirection={'column'}
                justifyContent="center"
                alignItems={'center'}
                gap={2}
                p={2}
              >
                <Box
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ width: '100%' }}
                >
                  <Typography
                    variant="h3"
                    color="text.secondary"
                    sx={{ ml: 'auto' }}
                  >
                    {t('Content')}:
                  </Typography>
                  <Button
                    variant="text"
                    startIcon={<EditTwoToneIcon />}
                    sx={{ ml: 'auto' }}
                    onClick={() => setEdit(true)}
                  >
                    {t('Edit')}
                  </Button>
                </Box>
                <Typography variant="h6" p={2}>
                  {text?.content}
                </Typography>
              </Box>
            </>
          )}
        </>
      </Box>
      {!text && (
        <Box
          sx={{
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            p: 5
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={64} disableShrink thickness={3} />
        </Box>
      )}
    </Dialog>
  );
}

const ensureFullURL = (url) => {
  if (!/^http(s?):\/\//.test(url)) {
    return 'https://' + url;
  }
  return url;
};

export default TextDetailsModal;
