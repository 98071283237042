import axios from 'axios';
import { config } from 'src/config';

export const getAllInstagramPosts = async (botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/instagram-post/${botId}`);
  return res.data;
};

export const getInstagramPost = async (id, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/instagram-post/${botId}/${id}/`);
  return res.data;
};

export const getFetchInstagramPosts = async (botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/instagram-post/${botId}/fetch/`);
  return res.data;
};

export const refreshPostSummary = async (postId, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.post(
    `${config.api_url}/instagram-post/${botId}/${postId}/refresh/`
  );
  return res.data;
};

export const fetchPostFromInstagram = async (botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.post(`${config.api_url}/instagram-post/${botId}/fetch/`);
  return res;
};

export const updatePostMetadata = async (postId, data, botId) => {
  const axiosInt = axios.create();
  const res = await axiosInt.put(
    `${config.api_url}/instagram-post/${botId}/${postId}/`,
    data
  );
  return res;
};

export const searchAllPosts = async (queries, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(
    `${config.api_url}/instagram-post/${botId}/search${queries}`
  );
  return res.data;
};

export const addInstagramProduct = async (postId, data, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.post(
    `${config.api_url}/instagram-post/${botId}/products/${postId}/`,
    data
  );
  return res.data;
};

export const deleteInstagramProduct = async (postId, productId, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.delete(
    `${config.api_url}/instagram-post/${botId}/products/${postId}/${productId}/`
  );
  return res.data;
};

export const addInstagramAccount = async (data) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;

  const res = await axiosInt.post(`${config.api_url}/instagram-account/`, data);
  return res.data;
};


export const getFacebookAppSecret = async () => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/facebook-page/secret`);
  return res.data;
};
