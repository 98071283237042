import React, { useState } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Box
} from '@mui/material';

const PrivacyPolicyPage = () => {
  const [supportEmail] = useState('support@zenithchat.co');
  const [privacyEmail] = useState('support@zenithchat.co');

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box
          sx={{
            mb: 2,
            borderBottom: 1,
            borderColor: 'primary.main',
            padding: 1
          }}
        >
          <Typography variant="h5" component="body1" gutterBottom>
            Effective Date: March 4, 2024
          </Typography>
        </Box>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            mb: 2,
            borderBottom: 1,
            borderColor: 'primary.main',
            paddingBottom: 2,
            textAlign: 'center'
          }}
        >
          ZenithChat Chatbot Privacy Policy Overview
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={{ lineHeight: '1.3', marginTop: '2rem' }}
        >
          Marko Bender PR from Novi Sad proudly introduces the ZenithChat Chatbot
          ("Zenith AI", "we", "us", or "our"), a cutting-edge solution designed
          to revolutionize customer service on Instagram for businesses and
          e-commerce platforms. Hosted on our secure web application, ZenithChat
          Chatbot leverages advanced technology to provide 24/7 support,
          automating interactions to deliver instant responses to customer
          inquiries. This Privacy Policy outlines our commitment to safeguarding
          personal information and our practices for collecting, using, and
          managing data in compliance with industry standards and legal
          requirements.
        </Typography>
        <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
          To improve your interactions with ZenithChat Chatbot, we collect
          information such as email addresses for account creation and usernames
          for personalizing your experience. We also gather data on how you use
          our service to continually optimize our chatbot's performance,
          ensuring it meets your needs effectively.
        </Typography>
        <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
          In this Privacy Policy, we explain how we collect, use, and disclose
          information about you when you use our service, including the choices
          you have with respect to that information. We also describe the
          measures we take to protect the security of your data and how you can
          contact us about our privacy practices. By accepting our Terms of
          Service, you agree to the terms of this Privacy Policy.
        </Typography>
        <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
          In this Privacy Policy, we cover the following topics:
        </Typography>
        <List paragraph>
          <ListItem button component="a" href="#information-collection">
            <ListItemText primary="Information Collection and Use" />
          </ListItem>
          <ListItem button component="a" href="#cookie-management">
            <ListItemText primary="Cookie Management" />
          </ListItem>
          <ListItem
            button
            component="a"
            href="#personal-data-from-third-party-integrations"
          >
            <ListItemText primary="Personal Data from third-party integrations" />
          </ListItem>
          <ListItem button component="a" href="#legal-basis-for-processing">
            <ListItemText primary="Legal Basis for Processing" />
          </ListItem>
          <ListItem button component="a" href="#sharing-and-disclosure">
            <ListItemText primary="Sharing and Disclosure" />
          </ListItem>
          <ListItem button component="a" href="#data-security">
            <ListItemText primary="Data Security - Safeguarding Your Information" />
          </ListItem>
          <ListItem button component="a" href="#data-use-and-restrictions">
            <ListItemText primary="Data Use and Restrictions" />
          </ListItem>
          <ListItem button component="a" href="#why-do-we-collect-your-data">
            <ListItemText primary="Why do we collect your data?" />
          </ListItem>
          <ListItem button component="a" href="#user-rights-and-choices">
            <ListItemText primary="User Rights and Choices" />
          </ListItem>
          <ListItem button component="a" href="#data-retention">
            <ListItemText primary="Data Retention - Preserving Data Integrity" />
          </ListItem>
          <ListItem button component="a" href="#intellectual-property-rights">
            <ListItemText primary="Intellectual Property Rights" />
          </ListItem>
          <ListItem button component="a" href="#international-data-transfers">
            <ListItemText primary="International Data Transfers - Cross-Border Data Flow" />
          </ListItem>
          <ListItem button component="a" href="#third-party-services">
            <ListItemText primary="Third-Party Services - External Service Providers" />
          </ListItem>
          <ListItem button component="a" href="#incident-reporting">
            <ListItemText primary="Incident Reporting and Data Breach Handling" />
          </ListItem>
          <ListItem button component="a" href="#childrens-privacy">
            <ListItemText primary="Children's Privacy - Protecting the Youngest Members of Our Community" />
          </ListItem>
          <ListItem button component="a" href="#privacy-policy-updates">
            <ListItemText primary="Updates and Changes to the Privacy Policy" />
          </ListItem>
          <ListItem button component="a" href="#links-to-other-websites">
            <ListItemText primary="Links to Other Websites" />
          </ListItem>
          <ListItem button component="a" href="#contact-us">
            <ListItemText primary="Contact Us" />
          </ListItem>
        </List>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="information-collection">
            Information Collection and Use
          </Typography>
          <Typography variant="h5" gutterBottom>
            Data Management and Utilization Practices
          </Typography>
          <List>
            <ListItem divider>
              <ListItemText
                primary="Cookie Usage:"
                secondary="We use cookies to enhance and personalize your user experience. Cookies are small data files stored on your device that help us improve our website's functionality, understand user interactions, and support secure log-in sessions. You can manage cookie settings through your browser to refuse cookies or to alert you when cookies are being sent."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Account Information for Personalized Service:"
                secondary="The creation of an account involves collecting essential details such as email and username. This information is integral to personalizing our service offering, ensuring that users receive the most relevant and tailored chatbot interactions. Most of the data we collect is provided directly by users during account creation, or from integrations with social media platforms (e.g., Facebook, Instagram), or through interactions with the chatbot. Thus, the data collected is limited to what is necessary for the provision of our services and depends on the user’s choices and actions."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Form Submitted Data:"
                secondary="When users submit forms on our website, we collect the data shown in the form, and also the visitor’s IP address and browser user agent string to help spam detection and improve user experience. We may use the information to contact you about your inquiry, respond to your requests, and improve our services. We do not use the information submitted through the contact form for marketing purposes unless you explicitly opt-in to receive marketing communications."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Optimizing User Experience Through Advanced Technologies:"
                secondary="Our application enhances user experience by using both cookies and local storage technologies for data retention. While cookies help in personalizing and improving the efficiency of our service, local storage minimizes load times and enhances session management without sending data back to the server. This dual approach helps us balance functionality with privacy concerns, ensuring a seamless experience while minimizing the data footprint on user devices."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Streamlined Data Processing for Efficiency:"
                secondary="We utilize a combination of local storage, session technologies, and cookies to optimize data storage and access. This hybrid approach enables us to deliver a robust and user-friendly service. By using these technologies, our chatbot can respond promptly and accurately to user interactions, ensuring efficiency without the extensive privacy concerns typically associated with sole reliance on cookies."
              />
            </ListItem>
          </List>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="cookie-management">
            Cookie Management
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            You have control over how cookies are handled on your device. Most
            web browsers offer the ability to block or delete cookies from your
            device. However, disabling cookies may affect the functionality and
            features available on our website. To manage cookies, access the
            settings menu in your browser and follow the instructions for
            cookies or privacy settings.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography
            variant="h4"
            gutterBottom
            id="personal-data-from-third-party-integrations"
          >
            Personal Data from third-party integrations
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            In the course of providing our services, the collection of personal
            data via third-party integrations is essential. During the chatbot
            creation process, we source data from platforms such as Facebook and
            Instagram. This practice is aimed at expanding the chatbot's
            functionalities and enhancing the overall user experience through
            seamless integration with these services. The types of data
            collected include, but are not limited to, Facebook and Instagram
            page details, among other information critical for the optimal
            operation of the chatbot. Additionally, chatbot messages and
            interactions are stored for the purposes of analysis and service
            improvement.
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="h4" gutterBottom id="legal-basis-for-processing">
            Legal Basis for Processing
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            ZenithChat Chatbot, under the stewardship of Marko Bender PR, engages
            in data processing activities based on a solid legal framework to
            ensure compliance with applicable laws, including the General Data
            Protection Regulation (GDPR) and other relevant privacy laws. Our
            processing activities are grounded on the following legal bases:
          </Typography>

          <List>
            <ListItem divider>
              <ListItemText
                primary="Consent:"
                secondary="We may process personal information based on your consent, which you provide when you create an account or interact with our chatbot. You have the right to withdraw your consent at any time by contacting us."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Contractual Necessity:"
                secondary="We process personal data to fulfill our contractual obligations with you, such as providing customer support and responding to inquiries."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Legal Obligations:"
                secondary="We may process personal data to comply with legal obligations, such as responding to lawful requests from law enforcement authorities."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Legitimate Interests:"
                secondary="We may process personal data based on our legitimate interests, such as improving our services, conducting research, and protecting our legal rights."
              />
            </ListItem>
          </List>
        </Box>
        <Box my={4}>
          <Typography variant="h4" gutterBottom id="sharing-and-disclosure">
            Sharing and Disclosure
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            ZenithChat Chatbot adheres to strict privacy principles, ensuring that
            your personal information is not sold or rented to any third
            parties. However, to facilitate our services efficiently, we may
            share your data with trusted service providers who play a crucial
            role in the delivery of our services. These partners may assist us
            in various operational tasks, including but not limited to web
            hosting, data analysis, and customer support.
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            Our commitment to safeguarding your personal information extends to
            our selection of third-party service providers. These entities are
            carefully chosen and are contractually bound to protect your
            information, with a clear mandate that prevents them from using your
            data for any purposes beyond the services they offer to us.
          </Typography>

          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            In addition to collaborations with service providers, we reserve the
            right to disclose your information when such action is mandated by
            law or is necessary to protect our rights, property, or safety, as
            well as that of our users or the public. This includes responding to
            legal processes or to law enforcement requests when we believe in
            good faith that disclosure is required by law.
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            We are dedicated to maintaining transparency and trust in how we
            handle your personal information, ensuring that any sharing or
            disclosure aligns with our commitment to your privacy and is
            compliant with applicable laws and regulations.
          </Typography>

          <List>
            <ListItem divider>
              <ListItemText
                primary="Service Providers:"
                secondary="We engage certain trusted third parties to perform functions and provide services to us, including hosting and maintenance, error monitoring, customer support, database storage and management, and direct marketing campaigns. We will share your personal data with these service providers, but only to the extent necessary to perform these functions and provide such services."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Compliance and Harm Prevention: "
                secondary="We may disclose your information if we believe it's necessary to comply with a legal obligation, protect the rights, property, or safety of Marko Bender PR, our users, or others, or to detect, prevent, or otherwise address fraud, security, or technical issues."
              />
            </ListItem>
          </List>
        </Box>
        <Box my={4}>
          <Typography variant="h4" gutterBottom id="data-security">
            Data Security - Safeguarding Your Information
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            ZenithChat Chatbot is committed to protecting your personal data from
            unauthorized access, disclosure, alteration, and destruction. We
            implement robust security measures to safeguard your information
            against potential threats and vulnerabilities.
          </Typography>
          <List>
            <ListItem divider>
              <ListItemText
                primary="Encryption & Security Measures:"
                secondary="Utilization of encryption for data in transit and at rest, secure server configurations, and regular security assessments."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Access Controls:"
                secondary="Limitation of access to personal data to employees, contractors, and agents who need to know this information in order to process it for us, and who are subject to strict contractual confidentiality obligations."
              />
            </ListItem>
          </List>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="data-use-and-restrictions">
            Data Use and Restrictions
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            The data collected by ZenithChat Chatbot is utilized strictly within
            the scope of our declared services and in accordance with legal
            requirements. Prohibited uses of our platform's data include, but
            are not limited to, unauthorized resale, data mining for competitive
            intelligence, and any activities that involve discrimination or are
            illegal. ZenithChat is dedicated to upholding the privacy and rights
            of our users in our data usage practices. Data collection occurs
            automatically during interactions with the Service, capturing
            information such as IP address, browser type and version, pages
            visited on our Service, visit dates and times, duration spent on
            those pages, unique device identifiers, and other diagnostic data.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography
            variant="h4"
            gutterBottom
            id="why-do-we-collect-your-data"
          >
            Why do we collect your data?
          </Typography>
          <List>
            <ListItem divider>
              <ListItemText
                primary="To Provide and Maintain Our Service:"
                secondary="We use your data to provide and maintain our Service, including to monitor the usage of our Service."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="To Manage Your Account:"
                secondary="We use your data to manage your account and to identify you as a user of the Service."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="To Contact You:"
                secondary="We may use your data to contact you with newsletters, marketing, or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="To Provide Customer Support:"
                secondary="We may use
                        your data to contact you for customer support purposes and to respond to any inquiries you make."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Core Functionality:"
                secondary="We use your data to provide core functionality of our Service, such as enabling you to log in and use the Service, chatbot interactions, data knowledge base, and other features."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Law Enforcement:"
                secondary="Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency)."
              />
            </ListItem>
          </List>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="user-rights-and-choices">
            User Rights and Choices
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            You have the right to access, correct, or delete your personal data,
            or restrict its processing. To exercise these rights, please contact
            us at{' '}
            <a
              style={{
                color: 'inherit',
                textDecoration: 'underline'
              }}
              href={`mailto:${privacyEmail}`}
            >
              {privacyEmail}
            </a>
            . We will respond to your request within 30 days.
          </Typography>
          <List>
            <ListItem divider>
              <ListItemText
                primary="Right to Access:"
                secondary="You have the right to request access to personal information we hold about you and to be informed about its use."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Right to Rectification: "
                secondary="You have the right to request the correction of inaccurate personal information we hold about you."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Right to Erasure:"
                secondary="Under certain circumstances, you may request the deletion of your personal information from our records."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Right to Restriction of Processing:"
                secondary="You have the right to request that we restrict the processing of your personal information."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Right to Data Portability:"
                secondary="You have the right to receive your personal information in a structured, commonly used and machine-readable format."
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Right to Object:"
                secondary="You have the right to object to the processing of your personal information in certain circumstances, including processing for direct marketing."
              />
            </ListItem>
          </List>
        </Box>
        <Box my={4}>
          <Typography variant="h4" gutterBottom id="data-retention">
            Data Retention - Preserving Data Integrity
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            We retain personal information only for as long as necessary to
            fulfill the purposes outlined in this privacy policy, unless a
            longer retention period is required or permitted by law. This
            includes retaining information for the duration of your use of our
            service and for a reasonable period thereafter to comply with our
            legal obligations, resolve disputes, and enforce our agreements.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography
            variant="h4"
            gutterBottom
            id="intellectual-property-rights"
          >
            Intellectual Property Rights
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            Marko Bender PR respects the intellectual property rights of others
            and expects its users to do the same. We have established policies
            and procedures for addressing allegations of copyright infringement
            and for the removal of infringing content from our service. If you
            believe your work has been copied in a way that constitutes
            copyright infringement, please contact us.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography
            variant="h4"
            gutterBottom
            id="international-data-transfers"
          >
            International Data Transfers - Cross-Border Data Flow
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            Your data may be transferred to, and processed in, countries other
            than your own. We ensure such transfers comply with legal
            requirements by using data protection agreements based on standard
            contractual clauses approved by the European Commission.
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="h4" gutterBottom id="third-party-services">
            Third-Party Services - External Service Providers
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            Our service may contain links to third-party websites, plug-ins,
            services, social networks, or applications. Clicking on those links
            or enabling those connections may allow third parties to collect or
            share data about you. We do not control these third-party websites
            and are not responsible for their privacy statements.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="incident-reporting">
            Incident Reporting and Data Breach Handling
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            In the unlikely event of a data breach, Marko Bender PR is committed
            to promptly investigating the incident and taking necessary measures
            to mitigate any harm. Affected users will be notified in accordance
            with applicable laws and regulations. We also encourage users to
            report any security vulnerabilities or potential data breaches to
            our dedicated security team.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="childrens-privacy">
            Children's Privacy - Protecting the Youngest Members of Our
            Community
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            Our Service does not address anyone under the age of 18. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 18. If we discover that we have inadvertently
            collected personal information from children under 18 without
            parental consent, we will take immediate steps to delete the data.
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            If you are under 18, please do not provide any personal information
            on our Service. If you are a parent or guardian and you are aware
            that your child has provided us with personal information, please
            contact us. We encourage parents and guardians to monitor their
            children's online activities and to help enforce this Privacy Policy
            by instructing their children never to provide personal information
            without their permission.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="privacy-policy-updates">
            Updates and Changes to the Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            We may update this Privacy Policy to reflect changes in legal
            requirements or our data handling practices. We will notify you of
            significant changes through email or a prominent notice on our
            website, and we encourage you to review the Privacy Policy
            periodically.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom id="links-to-other-websites">
            Links to Other Websites
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            We have no control over and assume no responsibility for the
            content, privacy policies, or practices of any third party sites or
            services.
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="h4" gutterBottom id="contact-us">
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph style={{ lineHeight: '1.3' }}>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary={`By email: ${supportEmail}`}
                secondary="We will respond to your request within a reasonable timeframe."
              />
            </ListItem>
          </List>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicyPage;
