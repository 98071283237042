import axios from 'axios';
import { config } from 'src/config';

export const getAllAudioFiles = async (botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/audio/${botId}`);
  return res.data;
};

export const deleteAudioFile = async (id, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.delete(`${config.api_url}/audio/${botId}/${id}/`);
  return res.data;
};

export const searchAllAudioFiles = async (queries, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/audio/${botId}/search${queries}`);
  return res.data;
};

export const updateChunkContent = async (
  chunkId,
  encryptedText,
  encryptedSecretKey,
  botId
) => {
  const axiosInt = axios.create();
  const res = await axiosInt.put(
    `${config.api_url}/audio/${botId}/content/${chunkId}/`,
    {
      encrypted_content: encryptedText,
      encrypted_secret_key: encryptedSecretKey
    }
  );
  return res;
};

export const deleteChunk = async (chunkId, botId) => {
  const axiosInt = axios.create();
  const res = await axiosInt.delete(
    `${config.api_url}/audio/${botId}/content/${chunkId}/`
  );
  return res;
};

export const getChunksForAudioFile = async (audioId, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/audio/${botId}/matches/${audioId}/`);
  return res.data;
};

export const postAudio = async (data, botId) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const formData = getFormData(data);
  return axiosInt
    .post(`${config.api_url}/audio/${botId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

const getFormData = (data) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('title', data.title);
  const date = new Date(data.release_date);
  formData.append(
    'release_date',
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(date.getDate()).padStart(2, '0')}`
  );
  formData.append('document_type', data.document_type);
  formData.append('chunk_size', data.chunk_size);
  formData.append('overlap', data.overlap);
  formData.append('file_name', data.file?.name);
  formData.append('file_size', data.file?.size);
  formData.append('original_url', data?.original_url);
  formData.append('content_type', data.file?.type);
  formData.append('file_encrypted', data?.encryptedFile);
  formData.append('secret_key', data?.secretKey);
  return formData;
};
