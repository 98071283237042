import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import { Instagram } from 'react-feather';

const getMenuItems = () => {
  return [
    {
      heading: 'General',
      items: [
        {
          name: 'Knowledge base',
          icon: SchoolRoundedIcon,
          link: `/${localStorage.getItem('REACT_APP_CHATBOT_ID')}/Jobs`,
          items: [
            {
              name: 'Instagram posts',
              link: `/${localStorage.getItem(
                'REACT_APP_CHATBOT_ID'
              )}/Jobs/InstagramContent`
            },
            {
              name: 'Text',
              link: `/${localStorage.getItem(
                'REACT_APP_CHATBOT_ID'
              )}/Jobs/Text`,
              badgeTooltip: 'Import text content into yout knowledge database '
            },
            // {
            //   name: 'Files',
            //   link: `/${localStorage.getItem(
            //     'REACT_APP_CHATBOT_ID'
            //   )}/Jobs/Files`,
            //   badgeTooltip: 'Import text files into yout knowledge database '
            // },
            // {
            //   name: 'Audio',
            //   link: `/${localStorage.getItem(
            //     'REACT_APP_CHATBOT_ID'
            //   )}/Jobs/Audio`,
            //   badgeTooltip: 'Import audio files into yout knowledge database '
            // }
            // {
            //   name: 'Query knowlage base',
            //   link: `/${localStorage.getItem(
            //     'REACT_APP_CHATBOT_ID'
            //   )}/playground/semantic-search`,
            //   badgeTooltip:
            //     'Search for similar sentences in your knowledge database '
            // }
          ]
        }
      ]
    },
    {
      heading: 'Chatbot',
      items: [
        {
          icon: QuestionAnswerRoundedIcon,
          name: 'Message history',
          link: `/${localStorage.getItem(
            'REACT_APP_CHATBOT_ID'
          )}/Instagram/History`,
          badgeTooltip: 'All conversations with your bot'
        },
        {
          icon: Instagram,
          name: 'Link profile',
          link: `/${localStorage.getItem(
            'REACT_APP_CHATBOT_ID'
          )}/Facebook/Accounts`,
          badgeTooltip: 'Connect your Facebook pages to your chatbot'
        },
        {
          icon: BuildRoundedIcon,
          name: 'Settings',
          link: `/${localStorage.getItem(
            'REACT_APP_CHATBOT_ID'
          )}/Settings/Chatbot`,
          badgeTooltip: 'Configure your chatbot'
        }
      ]
    }
  ];
};

export default getMenuItems;
