import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Scrollbar from '../Scrollbar';
import { SidebarContext } from '../../contexts/SidebarContext';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useAuthToken } from 'src/hooks/useAuthToken';
import {
  Box,
  Drawer,
  styled,
  useTheme,
  Button,
  Divider,
  Zoom,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SidebarTopSection from './SidebarTopSection';
import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/Logo';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { withRetryOnAuthFailure } from 'src/utils/axios';
// import { getAllBots } from 'src/utils/chatbot_service';
import logo from '../../images/LogoBotSad.svg';
import { useChatbots } from 'src/contexts/ChatbotContext';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
        display:flex;
        flex-direction: column;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const { logOut } = useAuthToken();
  const { chatbots, fetchChatbots } = useChatbots();

  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [chatbots, setChatbots] = useState([]);

  useEffect(async () => {
    await fetchChatbots();
  }, []);

  const handleLogout = async () => {
    try {
      logOut();
      navigate('/Login');
    } catch (err) {
      console.error(err);
    }
  };

  const handleNavigateToFacebookAccounts = () => {
    if (localStorage.getItem('REACT_APP_CHATBOT_ID')) {
      navigate(
        `/${localStorage.getItem('REACT_APP_CHATBOT_ID')}/Facebook/Accounts`
      );
    } else {
      navigate('/Facebook/Accounts');
    }
  };

  const renderSidebarMenu = () => {
    return chatbots?.length > 0 ? (
      <SidebarMenu />
    ) : (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // height: '100%',
          minHeight: 'calc(100% - 200px)'
          // backgroundImage:
          //   'linear-gradient(to right, #f3f3f3, #f3f3f3 50%, #ffffff)'
        }}
      >
        <Zoom in style={{ transitionDelay: '500ms' }}>
          <Box
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backdropFilter: 'blur(10px)' // Blur effect for the background
            }}
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                textAlign: 'center',
                color: theme.palette.text.primary
              }}
            >
              {t('No chatbots found')}
            </Typography>
            <img
              src={logo}
              alt="logo"
              width="100"
              height="100"
              style={{ marginBottom: '20px' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNavigateToFacebookAccounts()}
              sx={{
                ':hover': {
                  transform: 'scale(1.05)' // Simple scale animation on hover
                },
                transition: 'transform .2s' // Smooth transition for the transform property
              }}
            >
              {t('Add Chatbot')}
            </Button>
          </Box>
        </Zoom>
      </Box>
    );
  };

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'flex'
          },
          position: 'fixed',
          left: 0,
          top: 0
        }}
      >
        <Scrollbar>
          <TopSection>
            <Box
              sx={{
                // width: 52,
                ml: 1,
                mt: 1,
                mb: 3
              }}
            >
              <div style={{ height: '100px' }}>
                <Logo />
              </div>
            </Box>
            <SidebarTopSection chatbots={chatbots} />
          </TopSection>
          {renderSidebarMenu()}
        </Scrollbar>
        <Box m={1} mt={'auto'}>
          <Divider />
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </SidebarWrapper>

      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box
                sx={{
                  // width: 52,
                  ml: 1,
                  mt: 1,
                  mb: 3
                }}
              >
                <div style={{ height: '100px' }}>
                  <Logo />
                </div>
              </Box>
              <SidebarTopSection chatbots={chatbots} />
            </TopSection>
            {renderSidebarMenu()}
          </Scrollbar>
          <Box m={1} mt={'auto'}>
            <Divider />
            <Button color="primary" fullWidth onClick={handleLogout}>
              <LockOpenTwoToneIcon
                sx={{
                  mr: 1
                }}
              />
              {t('Sign out')}
            </Button>
          </Box>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
