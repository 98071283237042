import axios from 'axios';
import { config } from 'src/config';

export const withRetryOnAuthFailure = async (fn, ...args) => {
  try {
    // Try executing the function
    return await fn(...args);
  } catch (error) {
    // Check if the error is an authentication error
    if (error.response && error.response.status === 401) {
      const isRefreshSuccessful = await refreshToken();
      if (isRefreshSuccessful) {
        // Retry the function
        return fn(...args);
      }
      // Handle the scenario where refresh failed - you might want to log out the user or show an error
      console.log('Token refresh failed. Logging out...');
      // Call a function to log out the user or handle this scenario as needed
      logOut();
      throw new Error('Authentication failed after token refresh.');
    }
    // If it's another type of error, just rethrow it
    throw error;
  }
};

const refreshToken = async () => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(config.token_key)}`;
  try {
    const token = localStorage.getItem(config.refresh_token_key)
    if (!token) {
      return false;
    }
    const res = await axiosInt.post(`${config.api_url}/token/refresh/`, {
      refresh: token
    });
    localStorage.setItem(config.token_key, res.data.access);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const logOut = () => {
  localStorage.removeItem(config.refresh_token_key);
  localStorage.removeItem(config.token_key);
};
