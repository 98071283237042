import { useRef, useState } from 'react';
import {
  Button,
  Box,
  Grid,
  Menu,
  Divider,
  MenuItem,
  TextField,
  Autocomplete
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Controller, useForm } from 'react-hook-form';

function FilterSection({ metadata, onSubmited }) {
  const { t } = useTranslation();
  const btnRef = useRef(null);
  const [openPeriod, setOpenMenuPeriod] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      order_by_field: null,
      order_by: ''
    },
    mode: 'onSubmit'
  });

  const onSubmit = (data) => {
    onSubmited(data);
    setOpenMenuPeriod(false);
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        ref={btnRef}
        onClick={() => setOpenMenuPeriod(true)}
        endIcon={<FilterListRoundedIcon fontSize="small" />}
      >
        {t('Order by')}
      </Button>
      <Menu
        anchorEl={btnRef.current}
        onClose={() => setOpenMenuPeriod(false)}
        open={openPeriod}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              pt: 1,
              minWidth: '360px',
              outline: 'none'
            }}
          >
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Controller
                  name="order_by_field"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: '100%' }}
                      value={field.value}
                      onChange={(_, data) => {
                        field.onChange(data);
                        if (data === null) {
                          handleSubmit(onSubmit)();
                        }
                      }}
                      limitTags={1}
                      options={Object.keys(metadata)}
                      getOptionLabel={(option) =>
                        t(metadata[option].description)
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          variant="outlined"
                          label={t('Select search field')}
                          placeholder={t('Select field...')}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  name="order_by"
                  control={control}
                  render={({ field }) => (
                    <TextField label={t('Order')} select {...field} fullWidth>
                      <MenuItem value={'asc'}>{t('Ascending')}</MenuItem>
                      <MenuItem value={'desc'}>{t('Descending')}</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
            <Divider
              sx={{
                mb: 2,
                mt: 2
              }}
            />
            <Box
              pb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button type="submit" variant="contained" size="small">
                {t('Order results')}
              </Button>
            </Box>
          </Box>
        </form>
      </Menu>
    </>
  );
}

export default FilterSection;
