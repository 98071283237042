import { useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemText,
  alpha,
  List,
  Popover,
  Typography,
  styled
} from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useTranslation } from 'react-i18next';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useChatbots } from 'src/contexts/ChatbotContext';
import logoSad from 'src/images/LogoBotSad.svg'

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
    background-color: ${alpha(theme.colors.alpha.black[100], 0.08)};

    .MuiButton-label {
      justify-content: flex-start;
    }

    &:hover {
      background-color: ${alpha(theme.colors.alpha.black[100], 0.12)};
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
    color: ${alpha(theme.sidebar.menuItemColor, 0.6)};

    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`
);

function SidebarTopSection({ chatbots }) {
  const { t } = useTranslation();
  const { activeChatbot, setActiveChatbot } = useChatbots();

  const navigate = useNavigate();

  const handleChatbotChange = (chatbot) => {
    if (activeChatbot?.id === chatbot.id) {
      toast.info(t(`Chatbot ${chatbot.name} is already active`));
      return;
    }

    localStorage.setItem('REACT_APP_CHATBOT_ID', chatbot.id);

    const pathName = window.location.pathname;

    let pathSegments = pathName.split('/');

    if (
      pathSegments.length > 1 &&
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
        pathSegments[1]
      )
    ) {
      pathSegments[1] = chatbot.id;
    } else {
      pathSegments.splice(1, 0, chatbot.id); // Insert at index 1
    }

    const newPath = pathSegments.join('/');
    navigate(newPath, { replace: true });
    setActiveChatbot(chatbot);
    handleClose();
    window.location.reload();
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigateToFacebookAccounts = () => {
    if (localStorage.getItem('REACT_APP_CHATBOT_ID')) {
      navigate(
        `/${localStorage.getItem('REACT_APP_CHATBOT_ID')}/Facebook/Accounts`
      );
      handleClose();
    } else {
      navigate('/Facebook/Accounts');
      handleClose();
    }
  };

  return (
    <>
      <UserBoxButton fullWidth color="secondary" ref={ref} onClick={handleOpen}>
        {activeChatbot?.image_url ? (
          <Avatar
            variant="rounded"
            alt={activeChatbot?.name}
            src={activeChatbot?.image_url}
            sx={{
              width: 48,
              height: 48
            }}
          />
        ) : (
          <img src={logoSad} style={{ height: '45px' }} />
        )}
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {activeChatbot ? activeChatbot?.name : ''}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {activeChatbot ? activeChatbot?.model : t('No chatbot selected')}
            </UserBoxDescription>
          </UserBoxText>
          <UnfoldMoreTwoToneIcon
            fontSize="small"
            sx={{
              ml: 1
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          {activeChatbot?.image_url ? (
            <Avatar
              variant="rounded"
              alt={activeChatbot?.name}
              src={activeChatbot?.image_url}
              sx={{
                width: 48,
                height: 48
              }}
            />
          ) : (
            <img src={logoSad} style={{ height: '45px' }} />
          )}
          <UserBoxText>
            <UserBoxLabel className="popoverTypo" variant="body1">
              {activeChatbot ? activeChatbot?.name : ''}
            </UserBoxLabel>
            <UserBoxDescription className="popoverTypo" variant="body2">
              {activeChatbot ? activeChatbot?.model : t('No chatbot selected')}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        {chatbots?.length > 0 ? (
          <List
            sx={{
              p: 1
            }}
            component="nav"
          >
            {chatbots?.map((chatbot) => (
              <ListItemButton
                key={chatbot.id}
                onClick={() => handleChatbotChange(chatbot)}
                selected={activeChatbot?.id === chatbot.id}
              >
                {chatbot.image_url ? (
                  <Avatar
                    variant="rounded"
                    alt={chatbot.name}
                    src={chatbot.image_url}
                    sx={{
                      width: 32,
                      height: 32,
                      mr: 1
                    }}
                  />
                ) : (
                  <AccountBoxTwoToneIcon />
                )}
                <ListItemText primary={chatbot.name} />
              </ListItemButton>
            ))}
          </List>
        ) : (
          <>
            <Typography
              sx={{
                p: 0.5
              }}
            >
              {t('')}
            </Typography>
          </>
        )}
        <Button
          color="primary"
          fullWidth
          onClick={() => {
            handleNavigateToFacebookAccounts();
          }}
        >
          {t('Create new chatbot')}
        </Button>

        <Divider />
        <Box m={1} />
      </Popover>
    </>
  );
}

export default SidebarTopSection;
