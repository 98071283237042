import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  IconButton,
  Avatar,
  Tooltip
} from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatDistanceToNow } from 'date-fns';
import { config } from 'src/config';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

const InstagramPostCard = ({ post }) => {
  const [postDate] = useState(new Date(post.start_at));
  const { t } = useTranslation();

  const renderMedia = () => {
    if (post.copyright_flagged) {
      return (
        <img
          src={`${config.media_url}/${post._media_url}`}
          alt="Content flagged by Instagram for copyright violation"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '60vh',
            objectFit: 'contain'
          }}
        />
      );
    }

    if (post.children && post.children.length > 0) {
      return (
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{ clickable: true }}
          navigation={{
            prevel: '.swiper-button-prev',
            nextel: '.swiper-button-next'
          }}
          className="mySwiper"
        >
          {post?.children.map((child, index) => (
            <SwiperSlide key={index}>
              <img
                src={`${config.media_url}/${child.media_url}`}
                alt={`Instagram post ${index + 1}`}
                style={{ width: '100%', height: 'auto' }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }
    return post._media_type === 'IMAGE' ? (
      <img
        src={`${config.media_url}/${post._media_url}`}
        alt="Instagram post"
        style={{
          width: '100%',
          height: 'auto',
          maxHeight: '60vh',
          objectFit: 'contain'
        }}
      />
    ) : (
      /* eslint-disable-next-line jsx-a11y/media-has-caption */
      <video
        controls
        style={{
          height: 'auto',
          display: 'block',
          maxWidth: '100%',
          margin: 'auto',
          maxHeight: '60vh',
          objectFit: 'contain'
        }}
      >
        <source
          src={`${config.media_url}/${post._media_url}`}
          type="video/mp4"
        />
        {t('Your browser does not support the video tag.')}
      </video>
    );
  };

  return (
    <Card style={{ height: '75vh', overflow: 'auto', position: 'relative' }}>
      {post.copyright_flagged && (
        // The media_url field is omitted from responses if the media contains copyrighted material or has been flagged for a copyright violation. Examples of copyrighted material can include audio on reels.
        <Tooltip
          title={t(
            'The content is flagged if the media contains copyrighted material. Examples of copyrighted material can include audio on reels.'
          )}
          arrow
          placement="top"
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              backgroundColor: 'rgba(255, 0, 0, 0.3)',
              '&:hover': {
                backgroundColor: 'rgba(255, 0, 0, 0.8)'
              },

              color: 'white',
              textAlign: 'center',
              padding: '1px 0',
              zIndex: 10
            }}
          >
            {t('This content is flagged for copyright violation')}
          </Box>
        </Tooltip>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 1.5
        }}
      >
        <Avatar src={post.profilePic} alt={post._username} />
        <Typography
          variant="subtitle2"
          component="span"
          sx={{ fontWeight: 'bold', marginLeft: 2 }}
        >
          {post._username}
        </Typography>
        <Tooltip title={t('Open in instagram')}>
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={() =>
              window.open(post._permalink, '_blank', 'noopener,noreferrer')
            }
          >
            <OpenInNewRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box paddingX={1}>{renderMedia()}</Box>
      <Box
        paddingX={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ flexGrow: 1, textAlign: 'left', py: 1 }}
        >
          {formatDistanceToNow(postDate, { addSuffix: true })}
        </Typography>
      </Box>
      <Box paddingX={2} mb={1}>
        {post.like_count && (
          <Typography variant="body1" color="textPrimary">
            {post.like_count} {t('likes')}
          </Typography>
        )}
      </Box>
      <Box paddingX={2} paddingBottom={2}>
        <Typography variant="body1">{post.caption}</Typography>
      </Box>
    </Card>
  );
};

export default InstagramPostCard;
