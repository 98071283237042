import React from 'react';
import {
  Card,
  Box,
  Grid,
  Avatar,
  Link,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import { EditTwoTone as EditTwoToneIcon } from '@mui/icons-material';
import { DeleteTwoTone as DeleteTwoToneIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const InstagramProduct = ({ product, removeProduct, editProduct }) => {
  const [isClicked, setIsClicked] = React.useState(false);
  const { t } = useTranslation();

  const handleNoMetadata = () => {
    toast.info('No metadata available for this product.');
  };

  return (
    <Card
      sx={{
        m: 1,
        pl: 2,
        pr: 2,
        position: 'relative',
        transition: 'transform 0.3s ease',
        height: isClicked ? 'auto' : '6vh',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)'
        }
      }}
      onClick={() => setIsClicked(!isClicked)}
    >
      <Box
        py={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Avatar
          alt="Product Image"
          src={product._product_image}
          sx={{ marginRight: 1, width: 60, height: 60 }}
        />
        <Tooltip
          arrow
          placement="top"
          title={
            <Link
              color="inherit"
              href={product.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to {product.name}
            </Link>
          }
        >
          <Typography variant="h4" gutterBottom noWrap>
            {product.name.length > 15
              ? `${product.name.substring(0, 20)}...`
              : product.name}
          </Typography>
        </Tooltip>
        <Grid>
          <Tooltip arrow placement="top" title={t('Edit product')}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                editProduct(product);
              }}
            >
              <EditTwoToneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title={t('Remove product')}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                removeProduct(product.id);
              }}
            >
              <DeleteTwoToneIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Box>
      {/* check if there is any metadata */}
      {isClicked &&
        (product.metadata ? (
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
              maxHeight: '200px',
              transition: 'max-height 0.5s ease'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'background.paper',
                boxShadow: 2,
                padding: '8px',
                overflow: 'auto',
                mb: 1
              }}
            >
              <Typography variant="body1">{product.metadata}</Typography>
            </Box>
          </Box>
        ) : (
          handleNoMetadata()
        ))
        // toast message that there is not metadata
      }
    </Card>
  );
};

export default InstagramProduct;
