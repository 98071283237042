import React, { useState } from 'react';
import { Typography, Button, TextField, Box } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useTranslation } from 'react-i18next';

const CardEditableAttributes = ({ editableAttributes, onSave }) => {
  const { t } = useTranslation();
  const [editStates, setEditStates] = useState(
    editableAttributes.reduce((acc, attr) => {
      acc[attr.name] = { isEditing: false, value: attr.value };
      return acc;
    }, {})
  );

  const handleEdit = (name) => {
    setEditStates({
      ...editStates,
      [name]: { ...editStates[name], isEditing: true }
    });
  };

  const handleChange = (name, newValue) => {
    setEditStates({
      ...editStates,
      [name]: { ...editStates[name], value: newValue }
    });
  };

  const handleSave = (attribute) => {
    if (onSave) {
      onSave(attribute.attribute, editStates[attribute.name].value);
    }
    setEditStates({
      ...editStates,
      [attribute.name]: { ...editStates[attribute.name], isEditing: false }
    });
  };

  const handleCancel = (name, originalValue) => {
    setEditStates({
      ...editStates,
      [name]: { isEditing: false, value: originalValue }
    });
  };
  return (
    editableAttributes &&
    editableAttributes.map((attribute, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10,
          marginTop: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Typography
            variant="body1"
            component="span"
            style={{
              fontWeight: 'bold',
              fontSize: '1rem',
              marginRight: '10px',
              minWidth: '100px' // Ensures label has space
            }}
          >
            {t(attribute.name) + ': '}
          </Typography>
          {!editStates[attribute.name]?.isEditing ? (
            <Typography
              variant="body1"
              component="span"
              style={{
                fontSize: '1rem',
                marginRight: '10px'
              }}
            >
              {editStates[attribute.name]?.value}
            </Typography>
          ) : (
            <TextField
              fullWidth
              maxRows={3}
              multiline
              value={editStates[attribute.name]?.value}
              onChange={(e) => handleChange(attribute.name, e.target.value)}
              style={{
                marginRight: '10px',
                resize: 'vertical'
              }}
            />
          )}
        </Box>
        <Box>
          {!editStates[attribute.name]?.isEditing ? (
            <Button
              startIcon={<EditTwoToneIcon />}
              size="small"
              onClick={() => handleEdit(attribute.name)}
            >
              {t('Edit')}
            </Button>
          ) : (
            <>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleSave(attribute)}
              >
                {t('Save')}
              </Button>
              <Button
                size="small"
                onClick={() => handleCancel(attribute.name, attribute.value)}
              >
                {t('Cancel')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    ))
  );
};

export default CardEditableAttributes;
