const REACT_APP_API_URL = '/api';
// const REACT_APP_API_URL = 'https://91.108.121.49:80/api';
// const REACT_APP_API_URL = 'https://www.srv504059.hstgr.cloud/api';
// const REACT_APP_API_URL = 'http://91.108.121.49:3000/api';

const REACT_APP_TOKEN_KEY = '7041249660/auth_token_gpt';
const REACT_APP_REFRESH_KEY = '1111943138/auth_refresh_token_gpt';
const REACT_APP_CHAT_SESSION_LAST = '1111943138/last_session_chat';
// const REACT_APP_FACEBOOK_APP_ID = '1546512912812984'
const REACT_APP_FACEBOOK_APP_ID = '1344144536282673';
const DOMAIN_NAME = 'app.zenithchat.co';

export const config = {
  api_url: process.env.REACT_APP_API_URL || REACT_APP_API_URL,
  token_key: process.env.REACT_APP_TOKEN_KEY || REACT_APP_TOKEN_KEY,
  refresh_token_key: process.env.REACT_APP_REFRESH_KEY || REACT_APP_REFRESH_KEY,
  last_session_key:
    process.env.REACT_APP_CHAT_SESSION_LAST || REACT_APP_CHAT_SESSION_LAST,
  // hardoced_bot_id: '076f8e05-0301-4860-bf64-08f533d5b59d'
  hardoced_bot_id: '076f8e05-0301-4860-bf64-08f533d5b59d',
  media_url: 'http://app.zenithchat.co/media',
  // media_url: 'https://91.108.121.49:80/media',
  // media_url: 'https://91.108.121.49:443/media',
  // backed_host: 'localhost:8000',
  // backed_host: '91.108.121.49:80', // this work for http on VPS
  backed_host: '91.108.121.49', // this work for http on VPS
  // backed_host: 'app.zenithchat.co:8000', // UNCOMMENT THIS LINE FOR PRODUCTION
  // backed_host: 'app.zenithchat.co', // UNCOMMENT THIS LINE FOR PRODUCTION
  // websocket_base_url: 'wss://app.zenithchat.co/ws', // UNCOMMENT THIS LINE FOR PRODUCTION

  max_tokens_per_acc: 300000,
  facebook_id:
    process.env.REACT_APP_FACEBOOK_APP_ID || REACT_APP_FACEBOOK_APP_ID,
  domain_name: DOMAIN_NAME
};
