import React, { Suspense } from 'react';
import { CircularProgress, CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useRoutes } from 'react-router-dom';
import router from './router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import axios from 'axios';

// import AppInit from './components/AppInit';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export default function App() {
  const content = useRoutes(router);

  return (
    <Suspense fallback={
      <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div >}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <>
            <CssBaseline />
            {content}
          </>
        </LocalizationProvider>
      </ThemeProvider>
    </Suspense >
  );
}
