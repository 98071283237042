import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const CardAttributes = ({ attributes }) => {
  const { t } = useTranslation();

  const processValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? t('Yes') : t('No');
    }
    if (typeof value === 'string' && !isNaN(Date.parse(value))) {
      return format(new Date(value), 'dd MMM yyyy');
    }
    if (typeof value === 'number') {
      return new Intl.NumberFormat().format(value);
    }
    if (typeof value === 'string' && value.startsWith('http')) {
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {t('LINK')}
        </a>
      );
    }
    if (typeof value === 'string' && value.length > 15) {
      return (
        <Tooltip title={value}>
          <span>{value.substring(0, 15)}...</span>
        </Tooltip>
      );
    }
    return value;
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        marginBottom: 10
      }}
    >
      {attributes &&
        attributes.map((attribute, index) => (
          <div key={index} style={{ marginBottom: '2px' }}>
            <Typography
              variant="body1"
              component="span"
              style={{
                fontWeight: 'bold',
                fontSize: '1rem'
              }}
            >
              {t(attribute.name) + ': '}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              style={{
                fontSize: '1rem'
              }}
            >
              {processValue(attribute.value)}
            </Typography>
          </div>
        ))}
    </div>
  );
};

export default CardAttributes;
