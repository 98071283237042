import { alpha } from '@mui/material/styles';
import { documentStatus } from '../data/data.ts';
import { t } from 'i18next';
// TODO fix translations for labels

export function formatDate(dateString) {
  const date = new Date(dateString);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  return date.toLocaleString('en-US', options);
}

export function getTextTypeLabel(type) {
  return typeToLabelMap[type] || 'Unknown';
}
export const typeToLabelMap = {
  BLOG: 'Blog post',
  NEWS: 'Newspaper post',
  FACEBOOK: 'Facebook post',
  TWITTER: 'Twitter post',
  OTHER: 'Other'
};

export function isNewDay(currentDate, lastDate) {
  return (
    currentDate.getDate() !== lastDate.getDate() ||
    currentDate.getMonth() !== lastDate.getMonth() ||
    currentDate.getFullYear() !== lastDate.getFullYear()
  );
}

const searchTypeToLabelMap = {
  lt: 'Less than',
  gt: 'Greater than',
  lte: 'Less Than or Equal To',
  gte: 'Greater Than or Equal To',
  eq: 'Equals',
  cts: 'Contains',
  range: 'Range'
};

export function getSearchTypeLabel(type) {
  return searchTypeToLabelMap[type] || type;
}

const typeToColorMap = (theme) => ({
  ERROR: alpha(theme.palette.error.main, 0.2),
  COMPLETE: alpha(theme.palette.success.main, 0.2),
  DELETED: alpha(theme.palette.warning.main, 0.2),
  CANCELED: alpha(theme.palette.info.main, 0.2)
});

const typeToColorMapText = (theme) => ({
  ERROR: theme.palette.error.main,
  COMPLETE: theme.palette.success.main,
  DELETED: theme.palette.warning.main,
  CANCELED: theme.palette.info.main
});

export function getTextStatusCollor(type, theme) {
  return typeToColorMap(theme)[type] || alpha(theme.palette.success.main, 0.3);
}

export function getTextStatusCollorText(type, theme) {
  return typeToColorMapText(theme)[type] || theme.palette.text;
}

export function extractAssetId(urlString) {
  const url = new URL(urlString);
  const params = new URLSearchParams(url.search);
  const assetId = params.get('asset_id');
  return assetId;
}

export function constructSearchURL({
  page,
  pageSize,
  searchType,
  searchField,
  value,
  from,
  to,
  orderBy,
  orderByField,
  status
}) {
  let baseURL = '?';
  let params = [];

  if (page !== null) params.push(`page=${page}`);
  if (pageSize !== null) params.push(`page_size=${pageSize}`);
  if (searchType !== null) params.push(`search_type=${searchType}`);
  if (searchField !== null) params.push(`search_field=${searchField}`);

  if (searchType === 'range') {
    if (from !== null) params.push(`from=${from}`);
    if (to !== null) params.push(`to=${to}`);
  } else if (value !== null) {
    params.push(`value=${value}`);
  }

  if (orderBy !== null) params.push(`order_by=${orderBy}`);
  if (orderByField !== null) params.push(`order_by_field=${orderByField}`);
  if (status !== undefined) params.push(`status=${status}`);

  return baseURL + params.join('&');
}

export const getStatusLabel = (value) => {
  const statusObj = documentStatus.find((status) => status.value === value);
  return statusObj ? statusObj.label : value;
};
