import React, { useState } from 'react';
import { Box, Card, Grid, Tabs, styled, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArticleTable from './TextTable';

function TextList() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('completed');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const tabs = [
    { value: 'completed', label: t('Completed text content') },
    { value: 'rejected', label: t('Rejected text content') }
  ];

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <TabsContainerWrapper>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>
      <FilterOptions>
        <Grid
          sx={{
            px: { xs: 0, md: 4, lg: 4 }
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12}>
            <ArticleTable
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              status={currentTab === 'rejected' ? 'ERROR' : 'COMPLETE'}
            />
          </Grid>
        </Grid>
      </FilterOptions>
    </>
  );
}

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
          padding: 0 ${theme.spacing(2)};
          position: relative;
          bottom: -1px;
    
          .MuiTabs-root {
            height: 44px;
            min-height: 44px;
          }
    
          .MuiTabs-scrollableX {
            overflow-x: auto !important;
          }
    
          .MuiTabs-indicator {
              min-height: 4px;
              height: 4px;
              box-shadow: none;
              bottom: -4px;
              background: none;
              border: 0;
    
              &:after {
                position: absolute;
                left: 50%;
                width: 28px;
                content: ' ';
                margin-left: -14px;
                background: ${theme.colors.primary.main};
                border-radius: inherit;
                height: 100%;
              }
          }
    
          .MuiTab-root {
              &.MuiButtonBase-root {
                  height: 44px;
                  min-height: 44px;
                  background: ${theme.colors.alpha.white[50]};
                  border: 1px solid ${theme.colors.alpha.black[10]};
                  border-bottom: 0;
                  position: relative;
                  margin-right: ${theme.spacing(1)};
                  font-size: ${theme.typography.pxToRem(13)};
                  color: ${theme.colors.alpha.black[80]};
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
    
                  .MuiTouchRipple-root {
                    opacity: .1;
                  }
    
                  &:after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    bottom: 0;
                    height: 1px;
                    content: '';
                    background: ${theme.colors.alpha.black[10]};
                  }
    
                  &:hover {
                    color: ${theme.colors.alpha.black[100]};
                  }
              }
    
              &.Mui-selected {
                  color: ${theme.colors.alpha.black[100]};
                  background: ${theme.colors.alpha.white[100]};
                  border-bottom-color: ${theme.colors.alpha.white[100]};
    
                  &:after {
                    height: 0;
                  }
              }
          }
      `
);

const FilterOptions = styled(Card)(
  ({ theme }) => `
          padding: ${theme.spacing(2)};
          border: 1px solid ${theme.colors.alpha.black[10]};
      `
);

export default TextList;
