import axios from 'axios';
import { config } from 'src/config';

export const getAllBots = async () => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/chatbot/`);
  return res.data;
};

export const getBotById = async (id) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.get(`${config.api_url}/chatbot/${id}/`);
  return res.data;
};

export const editBot = async (id, dto) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.put(`${config.api_url}/chatbot/${id}/`, dto);
  return res.data;
};

export const addChatbotForInstagramAccount = async (dto) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.post(`${config.api_url}/chatbot/`, dto);
  return res.data;
};

export const deleteBot = async (id) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.delete(`${config.api_url}/chatbot/${id}/`);
  // check if the response is successful (status code 204)
  if (res.status === 204) {
    return true;
  }
  return false;
};

export const revokeChatbotPermissions = async (id, accessToken) => {
  const axiosInt = axios.create();
  axiosInt.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    config.token_key
  )}`;
  const res = await axiosInt.post(
    `${config.api_url}/chatbot/${id}/revoke-perms/`,
    {
      access_token: accessToken
    }
  );
  if (res.status === 204 || res.status === 200) {
    return true;
  }
  return false;
};
