import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Box, useTheme } from '@mui/material';
import Footer from 'src/components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookiesPrivacyPopup from './CookiesPrivacyPopup';

const MainLayout = () => {
  const theme = useTheme();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setIsPopupOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('hasVisited', 'true');
    setIsPopupOpen(false);
  };

  return (
    <>
      <CookiesPrivacyPopup open={isPopupOpen} onClose={handleClose} />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        limit={10}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme.palette.mode}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column', // Setting the direction to column
          minHeight: '100vh' // Ensure it covers the entire viewport height
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            flex: 1,
            display: 'flex',
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              pl: `${theme.sidebar.width}`
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Box flexGrow={1}>
              <Outlet />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.up('lg')]: {
              pl: `${theme.sidebar.width}`
            }
          }}
        >
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
