import React, { useState } from 'react';
import {
  TextField,
  Box,
  Card,
  Grid,
  styled,
  Button,
  CircularProgress,
  MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { withRetryOnAuthFailure } from 'src/utils/axios';
import { addText, updateText } from 'src/utils/text-service';
import { getTextTypeLabel, typeToLabelMap } from 'src/utils/helper';
import { encryptContent } from 'src/utils/encryption';
// import InfoIcon from '../CustomComponents/InfoIcon';
import { useParams } from 'react-router-dom';

const EditorWrapper = styled(Box)(
  ({ theme }) => `
  
      .ql-editor {
        min-height: 100px;
      }
  
      .ql-snow .ql-picker {
        color: ${theme.colors.alpha.black[100]};
      }
  
      .ql-snow .ql-stroke {
        stroke: ${theme.colors.alpha.black[100]};
      }
  
      .ql-toolbar.ql-snow {
        border-top-left-radius: ${theme.general.borderRadius};
        border-top-right-radius: ${theme.general.borderRadius};
      }
  
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[30]};
      }
  
      .ql-container.ql-snow {
        border-bottom-left-radius: ${theme.general.borderRadius};
        border-bottom-right-radius: ${theme.general.borderRadius};
      }
  
      &:hover {
        .ql-toolbar.ql-snow,
        .ql-container.ql-snow {
          border-color: ${theme.colors.alpha.black[50]};
        }
      }
  `
);

function NewTextForm({ text, onChange }) {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, reset, getValues, setValue } = useForm({
    defaultValues: {
      content: text?.content || '',
      title: text?.title || getTextTypeLabel('BLOG'),
      type: text?.document_type || 'BLOG',
      original_url: text?.original_url || ''
    },
    mode: 'onBlur'
  });
  let { botId } = useParams();
  const charCount = watch('content');
  const charTitle = watch('title');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    const dto = {
      content: getValues('content'),
      title:
        getValues('title') !== ''
          ? getValues('title')
          : getTextTypeLabel(getValues('type')),
      type: getValues('type'),
      original_url: getValues('original_url')
    };
    const { encryptedContent, encryptedSecretKey } = await encryptContent(
      dto.content
    );
    dto.content = encryptedContent;
    dto.secret_key = encryptedSecretKey;

    if (text) {
      updateArticleHandler(dto, text.id);
      return;
    }
    addArticleHandler(dto);
  };

  const addArticleHandler = (dto) => {
    toast.promise(withRetryOnAuthFailure(addText, dto, botId), {
      pending: {
        render() {
          return t('Your text is beeing uploaded');
        },
        icon: true
      },
      success: {
        render() {
          reset();
          setIsSubmitting(false);
          return t('Text uploaded sucessfully!');
        },
        icon: true
      },
      error: {
        render({ err }) {
          console.log(err);
          setIsSubmitting(false);
          return t(
            'There has been problem while uploading your text, please try again.'
          );
        },
        icon: true
      }
    });
  };

  const updateArticleHandler = (dto, id) => {
    toast.promise(withRetryOnAuthFailure(updateText, dto, id, botId), {
      pending: {
        render() {
          return t('Your text is being updated');
        },
        icon: true
      },
      success: {
        render() {
          onChange();
          setIsSubmitting(false);
          return t('Text updated sucessfully!');
        },
        icon: true
      },
      error: {
        render({ err }) {
          console.log(err);
          return t(
            'There has been problem while updating your text, please try again.'
          );
        },
        icon: true
      }
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          p: 3,
          maxWidth: '1800px',
          mx: 'auto'
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  select
                  {...field}
                  label={t('Text type')}
                  fullWidth
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    if (
                      Object.values(typeToLabelMap).includes(getValues('title'))
                    ) {
                      setValue('title', getTextTypeLabel(e.target.value));
                    }
                  }}
                  error={!!fieldState.error}
                >
                  <MenuItem value={'BLOG'}>{t('Blog post')}</MenuItem>
                  <MenuItem value={'NEWS'}>{t('News website')}</MenuItem>
                  <MenuItem value={'FACEBOOK'}>{t('Facebook post')}</MenuItem>
                  <MenuItem value={'TWITTER'}>{t('Twitter post')}</MenuItem>
                  <MenuItem value={'OTHER'}>{t('Other')}</MenuItem>
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="original_url"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  label={t('Text link')}
                  name="original_url"
                  placeholder={t('Text link here...')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('The title field is required')
                },
                maxLength: {
                  value: 300,
                  message: t('Title can not be longer than 300chars')
                }
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  label={t('Text title')}
                  name="title"
                  placeholder={t('Text title here...')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Controller
            name="content"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('The content field is required')
              },
              maxLength: {
                value: 1500 - charTitle.length,
                message: t(
                  `Content can not be longer than ${
                    1500 - charTitle.length
                  } caharcters`
                )
              }
            }}
            render={({ field, fieldState }) => (
              <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'column'}
                gap={1}
              >
                <EditorWrapper>
                  <TextField
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    name="content"
                    label={t('Text content')}
                    placeholder={t('Text content here...')}
                    variant="outlined"
                    multiline
                    rows={20}
                    required
                  />
                </EditorWrapper>
                <Box ml={'auto'} fontSize={'medium'}>
                  {charCount.length}/{1500 - charTitle.length}
                </Box>
              </Grid>
            )}
          />
          <Grid
            item
            xs={12}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-end'}
          >
            <Button
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              spacing={2}
            >
              {t('Upload')}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
}

export default NewTextForm;
