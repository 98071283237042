import React, { useEffect } from 'react';

import InstagramProduct from './InstagramProduct';
import NewInstagramProduct from './NewInstagramProduct';
import { withRetryOnAuthFailure } from 'src/utils/axios';
import {
  addInstagramProduct,
  deleteInstagramProduct
} from 'src/utils/instagram-service';
import { useParams } from 'react-router';

const InstagramProductList = ({ postId, products, showForm, editTrigered }) => {
  const [productsList, setProductsList] = React.useState(products);
  const [selectedProduct, setSelectedProduct] = React.useState({
    id: null,
    name: '',
    url: '',
    metadata: ''
  });
  const { botId } = useParams()

  useEffect(() => {
    if (!showForm) {
      setSelectedProduct({
        id: null,
        name: '',
        url: '',
        metadata: ''
      });
    }
  }, [showForm]);

  const onSubmitHandler = async (dto) => {
    try {
      const res = await withRetryOnAuthFailure(
        addInstagramProduct,
        postId,
        dto,
        botId
      );
      setProductsList(res.products);
      editTrigered();
      return res;
    } catch (err) {
      console.error('Failed to add product:', err);
      throw err;
    }
  };

  const removeProduct = (productId) => {
    withRetryOnAuthFailure(deleteInstagramProduct, postId, productId, botId)
      .then((res) => {
        setProductsList(res.products);
      })
      .catch((err) => {
        console.error('Failed to remove product:', err);
        throw err;
      });
  };

  const editProduct = (product) => {
    setSelectedProduct(product);
    editTrigered();
  };

  return (
    <>
      {productsList &&
        !showForm &&
        selectedProduct.id == null &&
        productsList.map((product) => (
          <InstagramProduct
            key={product.id}
            product={product}
            removeProduct={(productId) => removeProduct(productId)}
            editProduct={(product) => editProduct(product)}
            className="instagram-product"
          />
        ))}

      {showForm && (
        <NewInstagramProduct
          product={selectedProduct}
          onSubmitHandler={(dto) => onSubmitHandler(dto)}
        />
      )}
    </>
  );
};

export default InstagramProductList;
