import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useChatbots } from '../../contexts/ChatbotContext';

const Authenticated = ({ children }) => {
  const { isAuthenticated } = useAuthToken();
  const { chatbots, isLoading } = useChatbots();
  const location = useLocation();

  // TODO: Uncomment the following code block to enable the chatbot and authentication checks
  if (!isAuthenticated()) {
    if (location.pathname !== '/Login') {
      return <Navigate to="/Login" replace />;
    }
  } else if (
    chatbots &&
    chatbots.length === 0 &&
    !isLoading &&
    location.pathname !== '/Facebook/Accounts'
  ) {
    return <Navigate to="/Facebook/Accounts" replace />;
  }
  if (!isAuthenticated()) {
    if (location.pathname !== '/Login') {
      return <Navigate to="/Login" replace />;
    }
  }

  return children;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
