import { useContext } from 'react';
import { Box, alpha, IconButton, Tooltip, styled } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { SidebarContext } from '../../contexts/SidebarContext';
import HeaderUserbox from './Userbox';
import HeaderButtons from './Buttons';
import { useTranslation } from 'react-i18next';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(8px);
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { t } = useTranslation();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box
        component="span"
        sx={{
          display: { lg: 'none', xs: 'inline-block' }
        }}
      >
        <Tooltip title={t('Toggle Menu')} arrow>
          <IconButton color="primary" onClick={toggleSidebar}>
            {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      <Box
        display="flex"
        flexGrow={1}
        alignItems="center"
        justifyContent="flex-start"
      >
        <HeaderButtons />
      </Box>

      <HeaderUserbox />
    </HeaderWrapper>
  );
}

export default Header;
