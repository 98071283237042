import forge from 'node-forge';
import axios from 'axios';
import { config } from 'src/config';

export const generateSecretKey = () => {
  const array = new Uint8Array(16);
  crypto.getRandomValues(array);
  return Array.from(array)
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
};

export async function symmetricEncryption(plaintext, secretKey) {
  const keyBuffer = new TextEncoder().encode(secretKey);
  const ptUint8 = new TextEncoder().encode(plaintext);

  // Use WebCrypto API to import key
  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    keyBuffer,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt']
  );

  // Generate a 16-byte nonce (instead of the typical 12-byte nonce)
  const iv = window.crypto.getRandomValues(new Uint8Array(16));

  // Encrypt using AES-GCM mode
  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
      tagLength: 128
    },
    cryptoKey,
    ptUint8
  );

  // Separate out the ciphertext and the tag from the encrypted data
  const tag = encryptedData.slice(
    encryptedData.byteLength - 16,
    encryptedData.byteLength
  );
  const actualCiphertext = encryptedData.slice(
    0,
    encryptedData.byteLength - 16
  );

  // Concatenate nonce + tag + ciphertext
  const combined = new Uint8Array(
    iv.byteLength + tag.byteLength + actualCiphertext.byteLength
  );
  combined.set(new Uint8Array(iv), 0);
  combined.set(new Uint8Array(tag), iv.byteLength);
  combined.set(
    new Uint8Array(actualCiphertext),
    iv.byteLength + tag.byteLength
  );

  const base64Encrypted = uint8ArrayToBase64(combined);

  return base64Encrypted;
}

export const symmetricEncryptionFile = async (file, secretKey) => {
  const fileArrayBuffer = await file.arrayBuffer();
  const keyBuffer = new TextEncoder().encode(secretKey);
  // const ptUint8 = new TextEncoder().encode(plaintext);
  const ptUint8 = new Uint8Array(fileArrayBuffer);

  // Use WebCrypto API to import key
  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    keyBuffer,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt']
  );

  // Generate a 16-byte nonce (instead of the typical 12-byte nonce)
  const iv = window.crypto.getRandomValues(new Uint8Array(16));

  // Encrypt using AES-GCM mode
  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
      tagLength: 128
    },
    cryptoKey,
    ptUint8
  );

  // Separate out the ciphertext and the tag from the encrypted data
  const tag = encryptedData.slice(
    encryptedData.byteLength - 16,
    encryptedData.byteLength
  );
  const actualCiphertext = encryptedData.slice(
    0,
    encryptedData.byteLength - 16
  );

  // Concatenate nonce + tag + ciphertext
  const combined = new Uint8Array(
    iv.byteLength + tag.byteLength + actualCiphertext.byteLength
  );
  combined.set(new Uint8Array(iv), 0);
  combined.set(new Uint8Array(tag), iv.byteLength);
  combined.set(
    new Uint8Array(actualCiphertext),
    iv.byteLength + tag.byteLength
  );

  const base64Encrypted = uint8ArrayToBase64(combined);

  return base64Encrypted;
};

function uint8ArrayToBase64(u8Array) {
  let binary = '';
  const len = u8Array.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(u8Array[i]);
  }
  return btoa(binary);
}

export const asymmetricEncryption = async (text) => {
  try {
    const response = await axios.get(`${config.api_url}/public-key/`);
    const publicKeyPem = response.data['public_key'];
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

    const encrypted = publicKey.encrypt(text, 'RSA-OAEP');
    return forge.util.encode64(encrypted);
  } catch (error) {
    console.error('Error fetching public key:', error);
    return null;
  }
};

export const encryptContent = async (content) => {
  const secretKey = generateSecretKey();
  const encryptedContent = await symmetricEncryption(content, secretKey);
  const encryptedSecretKey = await asymmetricEncryption(secretKey);
  return { encryptedContent, encryptedSecretKey };
};
